import React, { useState, useEffect } from "react";
import { Document, Page } from "react-pdf";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import { fetchContractFileByStream } from "../../../redux/features/getContractFileSlice";
import { useAppDispatch, useAppSelector } from "../../../redux/store";
import { useNavigate } from "react-router-dom";
import Handle401Status from "../../../types/components/handleApiReasponseStatus";

const PdfViewByAPI = (fileName) => {
  const [pdfBlob, setPdfBlob] = useState(null);
  const [numPages, setNumPages] = useState(null);
  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }
  const navigate = useNavigate();
  const dispatchForFile = useAppDispatch();
  useEffect(() => {
    const fetchData = async () => {
      await fetchPdfStream();
    };
    fetchData();
  }, [fileName.fileName]);

  const fetchPdfStream = async () => {
    try {
      const response = dispatchForFile(
        fetchContractFileByStream(fileName.fileName)
      ).then((response) => {
        if (response.payload?.status === 401) {
          Handle401Status(navigate, window);
        }
        if (response.payload?.status === 200) {
          setPdfBlob(response.payload.data);
        }
      });

      return response;
    } catch (error) {
      console.error("Error fetching PDF stream:", error);
      return null;
    }
  };
  return (
    <div className="pdfContainer">
      {pdfBlob && (
        <Document file={pdfBlob} onLoadSuccess={onDocumentLoadSuccess}>
          {Array.from(new Array(numPages), (el, index) => (
            <Page
              key={`page_${index + 1}`}
              pageNumber={index + 1}
              renderAnnotationLayer={false}
              scale={1}
              renderTextLayer={false}
            />
          ))}
        </Document>
      )}
    </div>
  );
};
export default PdfViewByAPI;
