import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../store";
import { useState } from "react";
import axios from "axios";
import { deleteUserImage, downloadUserImage, uploadUserImage } from "../../networking/apiEndpoints";
import { setImageDelete, setImageDownload, setProfileImageUrl } from "./setDownloadImageSlice";
import { setActiveUserImage } from "./setUserInfoSlice";
import { useLoggedInUserInformation } from "./setUserInfoHook";
import { useFlashMessage } from "../flashMessage/flashMessageHook";
import { Error, ImageFileNotUploaded } from "../../utilities/Constants";
export const useProfileImage = () => {
    const [status, setStatus]= useState<boolean>(false);
    const dispatch = useDispatch();
    const { showFlashMessage } = useFlashMessage();
    const {Id} = useLoggedInUserInformation();
    const {profileImageUrl, imageDownload, imageDelete} = useSelector((state : RootState) => state.profileImage);
    const uploadProfileImage = async ({uploadImageData}:{uploadImageData:FormData}) => {
        try {
            var token = localStorage.getItem('token');
            const response = await axios.post(uploadUserImage, uploadImageData, {
                headers: { 'Content-Type': 'multipart/form-data', Authorization:`Bearer ${token}`}
            });
                if (response.status === 200) {
                    setStatus(response.data);
                }else{
                    showFlashMessage(ImageFileNotUploaded, Error, 3000);
                }
            } 
            catch (err) 
            {
                console.log(err);
            }
    }
    const getProfileImage = async ({userId, tenantId, currentUser=false}:{userId:string, tenantId: string, currentUser?:boolean}) => {
        try {
            var token = localStorage.getItem('token');
            const response = await axios.post(`${downloadUserImage}/${userId}/${tenantId}`, null ,{responseType : "blob" ,
                headers: { 'Content-Type': 'application/json', Authorization:`Bearer ${token}`}
            });
            if (response) {
                const imageUrl = URL.createObjectURL(response.data);
                if (currentUser){
                    dispatch(setActiveUserImage(imageUrl));
                    dispatch(setImageDownload(true));
                }else{
                    if(userId === Id){
                      dispatch(setActiveUserImage(imageUrl));
                      dispatch(setImageDownload(true));
                    }
                    dispatch(setProfileImageUrl(imageUrl));
                    dispatch(setImageDownload(true));
                }
         
            }
        } catch (err) {
            console.log(err);
        }
    }
    const deleteProfileImage = async ({userId, tenantId, currentUser=false}:{userId:string, tenantId:string, currentUser?:boolean}) => {
        try {
            var token = localStorage.getItem('token');
            const response = await axios.delete(`${deleteUserImage}/${userId}/${tenantId}`,{
                headers: { 'Content-Type': 'application/json', Authorization:`Bearer ${token}`}
            });
            if (response.status === 200) {
                if (currentUser){
                    dispatch(setActiveUserImage(""));
                    dispatch(setImageDelete(response.data.status));
                }else{
                    if(userId === Id){
                      dispatch(setActiveUserImage(""));
                      dispatch(setImageDelete(response.data.status));
                    }
                    dispatch(setProfileImageUrl(""));
                    dispatch(setImageDelete(response.data.status));
                }
            }
        } catch (err) {
            console.log(err);
        }
    }    
    return {uploadProfileImage, status, getProfileImage, profileImageUrl, imageDownload, deleteProfileImage, imageDelete}
} 