import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios, { AxiosResponse } from "axios";
import { getScreeningType} from "../../networking/apiEndpoints";
import { ScreeningTypeModelState } from "../../types/components/screeningTypeModel";

const initialState: ScreeningTypeModelState = {
    screeningTypeModel:{
    status:false,
    message:"",
    result: []
 } ,
  loading: false,
  error:null,
  responseStatus: 200
};

export const getScreeningTypeApi = createAsyncThunk<
  AxiosResponse,
  void,
  {
    rejectValue: AxiosResponse; // Specify the type for the rejected value
  }
>('getScreeningType/get', async (_arg, thunkApi) => {
    const response = await axios.get(getScreeningType , {
      headers:{"Content-Type": "application/json",Authorization:`Bearer ${localStorage.getItem('token')}`},validateStatus: function(status){return status < 500;}
    }) ;

    if (response.status === 401) {
      // Use rejectWithValue to handle the rejection
      return thunkApi.rejectWithValue(response);
    }

    return response;
});

export const getScreeningTypeSlice = createSlice({
  name: "getScreeningType",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getScreeningTypeApi.pending, (state) => {
      state.loading = true;
      state.error = '';
    })
    .addCase(getScreeningTypeApi.fulfilled, (state, action) => {
      if(action.payload !== undefined)
      state.screeningTypeModel = action.payload.data;
      state.loading = false;
      if(action.payload !== undefined)
        {
          state.responseStatus = action.payload.status;
        }
    }) 
    .addCase(getScreeningTypeApi.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message ?? '';
      if(action.payload !== undefined)
        {
          state.responseStatus = action.payload.status;
        }
    });   
  },
});

export default getScreeningTypeSlice.reducer;