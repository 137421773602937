import { format } from 'date-fns';
export function UTCToLocalTimeZone(utcDateString) {
    if(utcDateString==="" || utcDateString === undefined)
    return;
    let date = new Date(utcDateString);        
    const milliseconds = Date.UTC(
        date.getFullYear(),
        date.getMonth(),
        date.getDate(),
        date.getHours(),
        date.getMinutes(),
        date.getSeconds(),
    );
    const localTime = new Date(milliseconds);
    localTime.getDate() // local date
    localTime.getHours() // local hour
    return format(localTime, 'MM/dd/yy h:mm a');
}