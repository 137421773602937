import { RouteObject } from "react-router-dom";
import React, { lazy } from "react";
import { SharedPaths } from "../utilities/Constants";

const Profile = lazy(() => import("../pages/cst/Profile"));
const EditProfile = lazy(() => import("../pages/cst/Profile/editprofile"));
const ChangePassword = lazy(() => import("../login/ChangePassword"));
const RolesAndPermissions = lazy(
  () => import("../components/SharedComponents/RolesAndPermissions")
);
const UpsertRoles = lazy(() =>
  import("../components/SharedComponents/RolesAndPermissions/UpsertRoles").then(
    (module) => ({ default: module.UpsertRoles })
  )
);
const AddUser = lazy(() =>
  import("../components/SharedComponents/UserList/AddUser").then((module) => ({
    default: module.AddUser,
  }))
);
const UserList = lazy(() => import("../components/SharedComponents/UserList"));
const EditUser = lazy(
  () => import("../components/SharedComponents/UserList/EditUser")
);
const AssignUsers = lazy(() => import("../components/SharedComponents/RolesAndPermissions/AssignUsers"));

const userRoutes: RouteObject[] = [
  { path: SharedPaths.pathAddUser, element: <AddUser /> },
  { path: SharedPaths.pathUserList, element: <UserList /> },
  { path: `${SharedPaths.pathEditUser}/:id`, Component: () => <EditUser /> },
  { path: SharedPaths.pathRolesAndPermissions, element: <RolesAndPermissions /> },
  { path: SharedPaths.pathRoleAdd, element: <UpsertRoles /> },
  { path: `${SharedPaths.pathRoleEdit}/:id`, element: <UpsertRoles /> },
  { path: SharedPaths.pathProfile, element: <Profile /> },
  { path: `${SharedPaths.pathEditProfile}/:id`, Component: () => <EditProfile /> },
  { path: SharedPaths.pathChangePassword, element: <ChangePassword /> },
  { path: `${SharedPaths.pathAssignUser}/:id`, element: <AssignUsers /> },
];

export default userRoutes;
