import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { ImageDownloadState} from '../../types/pages/userInfoType';

const initialState: ImageDownloadState = {       
  profileImageUrl: "",
  imageDownload: false,
  imageDelete: false,
};
const profileImageSlice = createSlice({
  name: 'getProfileImage',
  initialState,
  reducers: {
    setProfileImageUrl(state,action : PayloadAction<string>){            
        state.profileImageUrl =  action.payload;
    },
    setImageDownload(state,action : PayloadAction<boolean>){            
        state.imageDownload =  action.payload;
    },
    setImageDelete(state,action : PayloadAction<boolean>){            
        state.imageDelete =  action.payload;
    }
  },
});

export const {setProfileImageUrl, setImageDownload, setImageDelete} = profileImageSlice.actions;
export default profileImageSlice.reducer;
