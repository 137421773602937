import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios, { AxiosResponse } from 'axios';
import { getScreeningTerms } from '../../networking/apiEndpoints';
import { ScreeningTermModelState, ScreeningTermsFilterModel } from '../../types/components/screeningTermModel';

const initialState: ScreeningTermModelState = {
    screeningTermModel:{
    status:false,
    message:"",
    result: []
 } ,
  loading: false,
  error:null,
  responseStatus: 200
};
// Define an async thunk to make the API call
export const getScreeningTermsByTenantIdApi = createAsyncThunk<
AxiosResponse,
ScreeningTermsFilterModel,
  {
  rejectValue:AxiosResponse
}
>(
    'getScreeningTermsByTenantId/get',
    async (screeningTermsFilterModel, thunkApi) => {
        const apiPayLoad = {
            ...screeningTermsFilterModel,
            method : screeningTermsFilterModel.method === "0" ? "" : screeningTermsFilterModel.method
        }
        try {
            const response = await axios.post(getScreeningTerms , apiPayLoad,{
                headers:{"Content-Type": "application/json",Authorization:`Bearer ${localStorage.getItem('token')}`},validateStatus: function(status){return status < 500;}
            });
            if(response.status === 401)
                {
                  return thunkApi.rejectWithValue(response);
                }         
              return response; 
        } catch (error) {
            throw error;
        }
    }
);
const getScreeningTermsByTenantIdSlice = createSlice({
    name: 'getScreeningTermsByTenantId',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getScreeningTermsByTenantIdApi.fulfilled, (state, action) => {
            state.screeningTermModel = action.payload.data;   
            state.loading = false;        
        });        
        builder.addCase(getScreeningTermsByTenantIdApi.pending, (state, action) => {            
            state.loading = true;        
        })
        .addCase(getScreeningTermsByTenantIdApi.rejected, (state, action) => {
            if(action.payload !== undefined)
              {
                state.responseStatus = action.payload.status;
              }
          });             
    },
});

export default getScreeningTermsByTenantIdSlice.reducer;