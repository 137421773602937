import { createSlice,  createAsyncThunk } from "@reduxjs/toolkit";
import { AddUserDiscliamerLogsType } from "../../../types/components/rass/addUserDiscliamerLogsType";
import axios from "axios";
import { addUserDisclaimerLogs } from "../../../networking/apiEndpoints";

const initialState: AddUserDiscliamerLogsType = {
  isDeleted : false ,
  isLoading :false ,
  message : ""  
};
export const addUserDiscliamerLogs  = createAsyncThunk(
    "addUserDiscliamerLogs/update",
    async (UserDisclaimerAcceptanceModal : {userId : string , tenantId : string},thunkApi) => {
        try {                   
          var token = localStorage.getItem('token');                    
          const response = await axios.post(`${addUserDisclaimerLogs}`, UserDisclaimerAcceptanceModal, {          
          headers:{"Content-Type": "application/json", Authorization:`Bearer ${token}`}
        });    
                  
        return response.data;
      } catch (err) {
        console.log(err);
      }
    }
  );
  
  export const addUserDisclaimerLogsSlice = createSlice({
    name: "addUserDiscliamerLogs",
    initialState,
    reducers: {},
    extraReducers: (builder) => {  
      builder.addCase(addUserDiscliamerLogs.fulfilled, (state, action ) => {
        
        if(action.payload?.status === 200)
        { 
          state.isDeleted  = true;
          state.isLoading = false;        
        }
        else{
            state.isDeleted = false;
            state.isLoading = false;  
        }
      });
      builder.addCase(addUserDiscliamerLogs.pending, (state, action) => {
        state.isLoading = true;
    }) 
      },
  });
  
  export default addUserDisclaimerLogsSlice.reducer;