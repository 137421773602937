import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  ContractListResponseState,
} from "../../../types/components/contractDetailsTypes";
import axios, { AxiosResponse } from "axios";
import {  
  getContractList,
  getEmailCreatorList,
  getFeedbackList,  
} from "../../../networking/apiEndpoints";
import { EmailCreatorResponseState } from "../../../types/components/rass/emailCreatorModel";

const initialState: EmailCreatorResponseState = {
  emailCreatorListResponse:{
    status:false,
    message:"",
    result: null,    
 } ,
  loading: false,
  error:null,  
  responseStatus: 200
};
export const fetchEmailCreatorList = createAsyncThunk<
AxiosResponse|any,
  any,
  {
    rejectValue: AxiosResponse;
  }
>(
  "emailCreatorList/fetch",
  async (searchFollowUpEmail: { search?: string, tenantId?: string,id?:string , idEmailTemplate?:string }, thunkApi) => {
    var token = localStorage.getItem('token');
    searchFollowUpEmail.tenantId = localStorage.getItem('tenantId')??"";      
      const response = await axios.post(`${getEmailCreatorList}`, searchFollowUpEmail, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        validateStatus: function (status) {
          return status < 500;
        },
      });      
      if (response.status === 401) {
        return thunkApi.rejectWithValue(response);
      }
      return response.data;    
  }
);



export const getEmailCreatorSlice = createSlice({
  name: "contractlist",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchEmailCreatorList.pending, (state) => {
      state.loading = true;
      state.error = '';
    })
    .addCase(fetchEmailCreatorList.fulfilled, (state, action) => {    
      state.emailCreatorListResponse = action.payload?.data;
      state.loading = false;
    }) 
    .addCase(fetchEmailCreatorList.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message ?? '';
      if (action.payload !== undefined) {
        state.responseStatus = action.payload.status;
    }
    });   
  },
});

export default getEmailCreatorSlice.reducer;
