import { useState } from 'react';
import { EditorState, ContentState } from 'draft-js';
import { useFlashMessage } from '../../redux/flashMessage/flashMessageHook';
import { ContentCopied, EmailConstants, Success } from '../../utilities/Constants';

const useEditor = (initialValue : any, isChangedEmailContent?: boolean, editorTitle? : string, isEmailVersionManual?: boolean) => {
    const { showFlashMessage } = useFlashMessage();
    const generatedEmail = EmailConstants.generatedEmail;
    const [isEmailGeneratedOpen, setIsEmailGeneratedOpen] = useState(false);
    const [editorState, setEditorState] = useState(() =>
        EditorState.createWithContent(ContentState.createFromText(initialValue))
    );
    const copyText = () => {
      const text = editorState.getCurrentContent().getPlainText();
      navigator.clipboard.writeText(text);
      showFlashMessage(ContentCopied, Success, 3000);
    }
    const handleCopy = () => {
      if (editorTitle && editorTitle === generatedEmail){
        if (isChangedEmailContent || isEmailVersionManual) {
          copyText();
        } else {
          setIsEmailGeneratedOpen(true);
        }
      }else{
        copyText();
      }
    };
    const closeEmailGeneratedPopup = () => {
        setIsEmailGeneratedOpen(false);
      }
    return {
        handleCopy, closeEmailGeneratedPopup, isEmailGeneratedOpen
    };
};

export default useEditor;
