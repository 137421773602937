import { createSlice,  createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { deleteScreeningContractsEndPoint } from "../../networking/apiEndpoints";
import { DeleteScreeningContractsPayload } from "../../types/pages/getPreProcessModel";


const initialState = {
  isDeleted : false,
  isLoading :false,   
};

export const deleteScreeningContractsApi  = createAsyncThunk(
    "deleteScreeningContracts",
    async (deleteScreeningContractsPayload: DeleteScreeningContractsPayload) => {
      try{
        var token = localStorage.getItem('token');
        const response = await axios.delete(deleteScreeningContractsEndPoint, {
            data :  deleteScreeningContractsPayload,
            headers:{"Content-Type": "application/json",Authorization:`Bearer ${token}`}
        });
        return response.data;
      }
      catch (err) {
        console.log(err);
      }
    }
);
export const deleteScreeningContractsSlice = createSlice({
  name: "deleteScreeningContracts",
  initialState,
  reducers: {       
  },
  extraReducers: (builder) => {
      builder.addCase(deleteScreeningContractsApi.pending, (state, action) => {
          state.isLoading = true;
      })
      .addCase(deleteScreeningContractsApi.fulfilled, (state, action ) => {
        if(action.payload === 200)
        { 
          state.isDeleted  = true;
          state.isLoading = false;        
        }
        else
        {
          state.isDeleted = false;
          state.isLoading = false;  
        }
      });
    }  
});
  
export default deleteScreeningContractsSlice.reducer;