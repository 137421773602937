import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "../store";
import { setActiveUserInformation, setActiveUserNbf, userInformationType } from "./setUserInfoSlice";

export const useLoggedInUserInformation = () => {
    const {Role, RoleId, TenantId, IsCST, IsRRAS, Id, Name, DisplayName, Email, userImage, nbf} = useSelector((state : RootState) => state.userInformation);
    const dispatch = useAppDispatch();
    const setUserInformation = (userInformation : userInformationType) => {
        dispatch(setActiveUserInformation(userInformation))
    }
    const setUserNbf = (userNbfInfo : string) => {
        dispatch(setActiveUserNbf(userNbfInfo))
    }
    return {setUserInformation,setUserNbf, Role, RoleId, TenantId, IsCST, IsRRAS, Id, Name, DisplayName, Email, userImage, nbf}
} 
