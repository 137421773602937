import { createSlice,  createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { deleteMessageAPI } from "../../../../networking/apiEndpoints";
import { DeleteMessageType } from "../../../../types/components/rass/deleteMessageType";

const initialState: DeleteMessageType = {
  isDeleted : false ,
  isLoading :false   
};
export const deleteMessageById  = createAsyncThunk(
    "deletemessage/delete",
    async (id : string) => {
        try {          
          var token = localStorage.getItem('token');     
          var tenantId = localStorage.getItem('tenantId');           
          const response = await axios.delete(deleteMessageAPI+ "/" + id + "/" + tenantId, {
          headers:{"Content-Type": "application/json", Authorization:`Bearer ${token}`}
        });              
        return response.status;
      } catch (err) {
        console.log(err);
      }
    }
  );
  
  export const deleteMessageSlice = createSlice({
    name: "deleteemailmessage",
    initialState,
    reducers: {},
    extraReducers: (builder) => {      
      builder.addCase(deleteMessageById.fulfilled, (state, action ) => {
        
        if(action.payload === 200)
        { 
          state.isDeleted  = true;
          state.isLoading = false;        
        }
        else{
            state.isDeleted = false;
            state.isLoading = false;  
        }
      });
        builder.addCase(deleteMessageById.pending, (state, action) => {
            state.isLoading = true;
        })
      },
  });
  
  export default deleteMessageSlice.reducer;