import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios, { AxiosResponse } from "axios";
import { upsertCompaniesModel, upsertCompaniesModelState } from "../../../types/components/rass/upsertCompaniesModel";
import { addEditCompaniesEndPoint } from "../../../networking/apiEndpoints";

const initialState: upsertCompaniesModelState = {
    upsertCompaniesModel: [],
    loading: false,
    error: null,
    showAlert: false,
    responseStatus: 200
};

export const addEditCompanies = createAsyncThunk<
AxiosResponse | any,
upsertCompaniesModel,
{
    rejectValue: AxiosResponse;
}>(
    "addEditCompanies/post",
    async (formData: upsertCompaniesModel, thunkApi) => {
        try {
            var token = localStorage.getItem('token');
            const response = await axios.post(addEditCompaniesEndPoint, formData, {
                headers: { "Content-Type": "application/json", Authorization:`Bearer ${token}`}
            });
            if (response.status === 401) {
                return thunkApi.rejectWithValue(response);
            }
            return response.data;
        } catch (err) {
            console.log(err);
        }
    }
);
export const addEditCompaniesSlice = createSlice({
    name: "addEditCompanies",
    initialState,
    reducers: {
        hideAlert(state) {
            state.showAlert = false;
        },
        showAlert(state) {
            state.showAlert = true;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(addEditCompanies.pending, (state) => {
                state.loading = true;
                state.error = '';
                state.showAlert = false;
            })
            .addCase(addEditCompanies.fulfilled, (state, action) => {
                state.upsertCompaniesModel = action.payload;
                if(action.payload !== undefined)
                    {
                      state.responseStatus = action.payload.status;
                    }
                state.loading = false;
                state.showAlert = true;
            })
            .addCase(addEditCompanies.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message ?? '';
                state.showAlert = false;
                if(action.payload !== undefined)
                    {
                      state.responseStatus = action.payload.status;
                    }
            });
    },
});
export const { showAlert, hideAlert } = addEditCompaniesSlice.actions;
export default addEditCompaniesSlice.reducer;