import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios, { AxiosResponse } from "axios";
import { addEditCompanyDetailsEndPoint } from "../../../networking/apiEndpoints";
import { upsertCompanyDetailsModel, upsertCompanyDetailsModelState } from "../../../types/components/rass/upsertCompanyDetailsModel";

const initialState: upsertCompanyDetailsModelState = {
    upsertCompanyDetailsModel: [],
    loading: false,
    error: null,
    showAlert: false,
    responseStatus: 200
};

export const addEditCompanyDetails = createAsyncThunk<
AxiosResponse | any,
upsertCompanyDetailsModel,
{
    rejectValue: AxiosResponse;
}>(
    "addEditCompanyDetails/post",
    async (formData: upsertCompanyDetailsModel, thunkApi) => {
        try {
            var token = localStorage.getItem('token');
            const response = await axios.post(addEditCompanyDetailsEndPoint, formData, {
                headers: { "Content-Type": "application/json", Authorization:`Bearer ${token}`}
            });
            if (response.status === 401) {
                return thunkApi.rejectWithValue(response);
            }
            return response.data;
        } catch (err) {
            console.log(err);
        }
    }
);
export const addEditCompanyDetailsSlice = createSlice({
    name: "addEditCompanyDetails",
    initialState,
    reducers: {
        hideAlert(state) {
            state.showAlert = false;
        },
        showAlert(state) {
            state.showAlert = true;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(addEditCompanyDetails.pending, (state) => {
                state.loading = true;
                state.error = '';
                state.showAlert = false;
            })
            .addCase(addEditCompanyDetails.fulfilled, (state, action) => {
                state.upsertCompanyDetailsModel = action.payload;
                if(action.payload !== undefined)
                    {
                      state.responseStatus = action.payload.status;
                    }
                state.loading = false;
                state.showAlert = true;
            })
            .addCase(addEditCompanyDetails.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message ?? '';
                state.showAlert = false;
                if(action.payload !== undefined)
                    {
                      state.responseStatus = action.payload.status;
                    }
            });
    },
});
export const { showAlert, hideAlert } = addEditCompanyDetailsSlice.actions;
export default addEditCompanyDetailsSlice.reducer;