import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { deleteContractTerms } from '../../networking/apiEndpoints';
import axios from 'axios';


export const deleteContractTermById = createAsyncThunk(
    "deletecontractterm/delete",
    async (id: string) => {
        try {
            var token = localStorage.getItem('token');
            var tenantId = localStorage.getItem('tenantId');
            const response = await axios.delete(deleteContractTerms + "/" + id + "/" + tenantId, {
                headers:{"Content-Type": "application/json", Authorization:`Bearer ${token}`}
                        });    
            return response.status;
        } catch (err) {
            console.log(err);
        }
    }
);

interface ContractTermDeleteState {
    isDeleteSuccess: boolean;
    deleting: boolean;
    isDeleteError: string | null;
}

const initialState: ContractTermDeleteState = {
    isDeleteSuccess: false,
    deleting: false,
    isDeleteError: null,
};

const deleteContractTermSlice = createSlice({
    name: 'contractTerms',
    initialState: initialState,
    reducers: {
        hideContractTermAlert(state) {
            state.isDeleteSuccess = false;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(deleteContractTermById.pending, (state) => {
                state.deleting = true;
                state.isDeleteError = '';
            })
            .addCase(deleteContractTermById.fulfilled, (state, action) => {
                state.deleting = false;
                if (action.payload === 200) { state.isDeleteSuccess = true; }else{
                    state.isDeleteSuccess = false;
                }
            })
            .addCase(deleteContractTermById.rejected, (state, action) => {
                state.deleting = false;
                state.isDeleteError = action.error.message ?? '';
            });
    },
});

export const { hideContractTermAlert} = deleteContractTermSlice.actions;
export default deleteContractTermSlice.reducer;