import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { getPersonaData } from "../../../../networking/apiEndpoints";
import { PersonaDataResponseModel } from "../../../../types/components/rass/personaDataResponseModel";
import { PersonaDataType } from "../../../../types/components/rass/personaDataType";

export const getPersonaDataApi = createAsyncThunk(
    "personaData/fetch",
    async (personaDataType:PersonaDataType, thunkApi) => {        
        const token = localStorage.getItem('token');
        try {
            const response = await axios.post(getPersonaData, personaDataType, {
                headers:{"Content-Type": "application/json", Authorization:`Bearer ${token}`},validateStatus: function(status){return status < 500;}
            });
            if(response.status === 401)
                {
                return thunkApi.rejectWithValue(response);
            }
            return response.data;
        } catch (error) {
            console.log(error);
        }
    }
);
interface PersonaDataState {
    data: PersonaDataResponseModel[] | undefined;
    loading: boolean;
    error: string | null;
    responseStatus:number;
}
const initialState: PersonaDataState = {
    data: [],
    loading: false,
    error: null,
    responseStatus: 200,
};
export const getPersonaDataSlice = createSlice({
    name: "getPersonaData",
    initialState,
    reducers: {
    },
    extraReducers: (builder) => {
        builder
            .addCase(getPersonaDataApi.pending, (state, action) => {
                state.error = '';
                state.loading = true;
            })
            .addCase(getPersonaDataApi.fulfilled, (state, action) => {
                state.data = action.payload;
                if(action.payload !== undefined)
                    {
                      state.responseStatus = action.payload.status;
                    }                
                state.loading = false;
            })
            .addCase(getPersonaDataApi.rejected, (state, action) => {
                state.error = action.error.message ?? '';
                state.loading = false;
            });
    },
});
export default getPersonaDataSlice.reducer;