import { RouteObject } from "react-router-dom";
import React, { lazy } from "react";
import { CSTPaths } from "../utilities/Constants";
import PreProcessingContractDetail from "../pages/cst/PreProcessing/preprocessingcontractdetails";

const ContractList = lazy(() => import("../pages/cst/ContractList"));
const PreProcessing = lazy(() => import("../pages/cst/PreProcessing"));
const ContractTypeList = lazy(() => import("../pages/cst/ContractType/List"));
const ContractTermList = lazy(() => import("../pages/cst/ContractTermList"));
const ContractDetail = lazy(
  () => import("../pages/cst/ContractList/contractdetail")
);
const UploadNewContract = lazy(() => import("../pages/cst/UploadNewContract"));
const FeedbackLists = lazy(() => import("../pages/cst/Feedback/Index"));
const ContractReports = lazy(
  () => import("../pages/cst/ContractReports/Index")
);
const AddContractType = lazy(
  () => import("../pages/cst/ContractType/AddContractType")
);
const AddContractTerm = lazy(
  () => import("../components/cst/ContractTerms/addcontractterm")
);
const AddScreeningTerm = lazy(
  () => import("../pages/cst/ScreeningTerm/addscreeningterm")
);
const EditScreeningTerm = lazy(
  () => import("../pages/cst/ScreeningTerm/editscreeningterm")
);
const EditContractType = lazy(
  () => import("../pages/cst/ContractType/EditContractType")
);
const EditContractTerm = lazy(
  () => import("../components/cst/ContractTerms/editcontractterm")
);
const EditUploadedContract = lazy(
  () => import("../pages/cst/EditUploadedContract")
);
const ChildContractList = lazy(() => import("../pages/cst/AttachContract"));

const ScreeningTermsList = lazy(()=> import("../pages/cst/ScreeningTerm/index"))

const cstRoutes: RouteObject[] = [
  { path: "/", element: <ContractList /> },
  { path: CSTPaths.pathContractTypes, element: <ContractTypeList /> },
  { path: CSTPaths.pathContractTerms, element: <ContractTermList /> },
  { path: CSTPaths.pathContractDetails, element: <ContractDetail /> },
  { path: CSTPaths.pathPreProcessingContractDetails, element: <PreProcessingContractDetail /> },
  { path: CSTPaths.pathUploadContract, element: <UploadNewContract /> },
  { path: CSTPaths.pathUsersFeedback, element: <FeedbackLists /> },
  { path: CSTPaths.pathContractReports, element: <ContractReports /> },
  { path: CSTPaths.pathScreeningTerms, element: <ScreeningTermsList /> },
  { path: CSTPaths.pathPreProcessing, element: <PreProcessing /> },
  {
    path: CSTPaths.pathAddContractType,
    Component: () => <AddContractType mode="add" itemId={""}></AddContractType>,
  },
  {
    path: CSTPaths.pathAddContractTerm,
    Component: () => (
      <AddContractTerm
        mode="add"
        itemId={""}
        isExpanded={false}
      ></AddContractTerm>
    ),
  },
  {
    path: `${CSTPaths.pathEditContractType}/:id`, 
    Component: () => <EditContractType></EditContractType>,
  },
  {
    path: `${CSTPaths.pathEditContractTerm}/:id`,
    Component: () => <EditContractTerm></EditContractTerm>,
  },
  { path: CSTPaths.pathEditUploadedContract, element: <EditUploadedContract /> },
  { path: CSTPaths.pathGetChildContractList, element: <ChildContractList /> },
  {
    path: CSTPaths.pathAddScreeningTerm,
    Component: () => (
      <AddScreeningTerm
        mode="add"
        itemId={""}
        isExpanded={false}
      />
    ),
  },
  {
    path: `${CSTPaths.pathEditScreeningTerm}/:id`,
    Component: () => <EditScreeningTerm></EditScreeningTerm>,
  },
];

export default cstRoutes;
