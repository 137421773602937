import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { upsertGeneratedMessage } from "../../../../networking/apiEndpoints";
import { GenerateMessageUpsertRequestModel } from "../../../../types/components/rass/genrateMessageUpsertReqModel";

export const upsertGeneratedMessageApi = createAsyncThunk(
    "generateMessage/upsert",
    async (reqModel:GenerateMessageUpsertRequestModel, thunkApi) => {        
        const token = localStorage.getItem('token');
        try {
            const response = await axios.post(upsertGeneratedMessage, reqModel, {
                headers:{"Content-Type": "application/json", Authorization:`Bearer ${token}`},validateStatus: function(status){return status < 500;}
            });
            if(response.status === 401)
                {
                return thunkApi.rejectWithValue(response);
            }
            return response.data;
        } catch (error) {
            console.log(error);
        }
    }
);
interface GeneratedMessageDataState {
    data: [] | undefined;
    loading: boolean;
    error: string | null;
    responseStatus:number;
}
const initialState: GeneratedMessageDataState = {
    data: [],
    loading: false,
    error: null,
    responseStatus: 200,
};
export const upsertGeneratedMessageSlice = createSlice({
    name: "upsertGeneratedMessage",
    initialState,
    reducers: {
    },
    extraReducers: (builder) => {
        builder
            .addCase(upsertGeneratedMessageApi.pending, (state, action) => {
                state.error = '';
                state.loading = true;
            })
            .addCase(upsertGeneratedMessageApi.fulfilled, (state, action) => {
                state.data = action.payload;
                if(action.payload !== undefined)
                    {
                      state.responseStatus = action.payload.status;
                    }                
                state.loading = false;
            })
            .addCase(upsertGeneratedMessageApi.rejected, (state, action) => {
                state.error = action.error.message ?? '';
                state.loading = false;
            });
    },
});
export default upsertGeneratedMessageSlice.reducer;