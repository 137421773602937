import { useNavigate } from "react-router-dom";
import TextBox from "../components/cst/TextField";
import Button from "../components/cst/Button";
import { ChangeEvent, useState } from "react";
import {
  forgotPasswordFail,
  forgotPasswordSucess,
  regExUserName,
} from "../utilities/Constants";
import { isEmailExists } from "../redux/features/isEmailExistsSlice";
import { useAppDispatch, useAppSelector } from "../redux/store";
import { AlertTitle, Box, CircularProgress } from "@mui/material";
import { ForgotPasswod } from "../types/components/passwordResetModel";
import { forgotPasswordAsyn } from "../redux/features/forgotPassword";
import CustomSnackbar from "../components/cst/CustomSnackbar";
import Alert from "@mui/material/Alert";

export default function ForgotPassword() {
  const [userName, setUserName] = useState<string>("");
  const [isButtonEnabled, setIsButtonEnabled] = useState<boolean>(false);
  const [pwdSucess, setpwdSucess] = useState<boolean>(false);
  const [pwdFails, setpwdFails] = useState<boolean>(false);
  const [error, setError] = useState("");
  const isEmailExistsLoader = useAppSelector(
    (state) => state.isEmailExists.isLoading
  );
  const loading = useAppSelector((state) => state.forgotPassword.loading);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const handleEmailChanged = (e: ChangeEvent<HTMLInputElement>) => {
    const userName = e.target.value;
    setUserName(userName);
    // Temporarily commented below code
    // const isUserNameValid = regExUserName.test(userName);
    const isEmpty = (val: string) => val.trim() === "";
    setIsButtonEnabled(!isEmpty(userName));
    setError("");
  };
  const handleOk = async () => {
    navigate("/");
  };
  const handleSubmit = async () => {
    setError("");
    if (!isButtonEnabled) {
      return;
    }
    dispatch(forgotPasswordAsyn(userName)).then((response) => {
      if (response.payload) {
        var result = response.payload;
        if (result.status) {
          setpwdSucess(true);
          setIsButtonEnabled(false);
        } else {
          setpwdFails(true);
          setIsButtonEnabled(true);
        }
      }
    });
  };
  return (
    <div className="login-waper">
      <div className="middle-ofwindow">
        <div className="login-logo">
          <img
            src={`${process.env.PUBLIC_URL}/riverside-company-logo-login.svg`}
            alt="Logo"
          />
        </div>
        {pwdSucess && (
          <div className="login-container forgot-success-msg">
            <div className="login-form">
              <Alert severity="success" className="rest-success-msg">
                <AlertTitle>Reset password email sent</AlertTitle>
                You should soon receive an email allowing you to reset your
                password. Please make sure to check your spam and trash if you
                can't find the email
              </Alert>

              <Button
                className="saveButton"
                type="contained"
                text="ok"
                onClick={handleOk}
              />
            </div>
          </div>
        )}

        {!pwdSucess && (
          <div className="login-container">
            {loading && (
              <Box className="login-loader">
                <CircularProgress />
              </Box>
            )}
            {isEmailExistsLoader && (
              <Box className="login-loader">
                <CircularProgress />
              </Box>
            )}

            <h1>Forgot Password</h1>
            <p>
              Enter your registered User Name we will send you instructions to
              reset your password
            </p>
            <div className="login-form">
              <TextBox
                id="user-name"
                name=""
                label="User Name"
                variant="outlined"
                size="small"
                required={true}
                onChange={handleEmailChanged}
                error={Boolean(error)}
                helperText={error}
                maxLength={50}
                className={
                  Boolean(error) ? "errorField rounded-input" : "loginemail"
                }
                formHelpTextProps={{
                  style: { textAlign: "left", fontSize: "12px" },
                }}
              />

              <Button
                className="saveButton"
                type="contained"
                text="Submit"
                disabled={!isButtonEnabled || loading}
                onClick={handleSubmit}
              />
            </div>
          </div>
        )}
        {pwdFails && (
          <div>
            <CustomSnackbar message={forgotPasswordFail} severity="error" />
          </div>
        )}
      </div>
    </div>
  );
}
