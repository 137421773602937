import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios, { AxiosResponse } from "axios";
import {getSeagageLastSyncInfoEndPoint} from "../../../networking/apiEndpoints";
import { SeagageLastSyncInfoResponseState } from "../../../types/components/rass/seagageLastSyncInfoModel";

const initialState: SeagageLastSyncInfoResponseState = {
  seagageLastSyncInfoResponseModel:{
    status:false,
    message:"",
    result: null,    
 } ,
  loading: false,
  error:null,  
  responseStatus: 200
};
export const getSeagageLastSyncInfo = createAsyncThunk<
  any,
  "",
  {
    rejectValue: AxiosResponse;
  }
  >("getSeagageLastSyncInfoEndPoint/fetch", 
  async (input: string = "", thunkApi) => {
    var token = localStorage.getItem('token');
    const tenantId = localStorage.getItem('tenantId');
    const response = await axios.get(getSeagageLastSyncInfoEndPoint + "/" + tenantId, {
      headers:{"Content-Type": "application/json", Authorization:`Bearer ${token}`},validateStatus: function(status){return status < 500;}
    });
    if(response.status === 401)
      {
        return thunkApi.rejectWithValue(response);
      }   
  return response.data;    
  }
);
export const getSeagageLastSyncInfoSlice = createSlice({
  name: "getSeagageLastSyncInfoSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getSeagageLastSyncInfo.pending, (state) => {
      state.loading = true;
      state.error = '';
    })
    .addCase(getSeagageLastSyncInfo.fulfilled, (state, action) => {    
      state.seagageLastSyncInfoResponseModel = action.payload;      
      state.loading = false;
    }) 
  },
});

export default getSeagageLastSyncInfoSlice.reducer;
