import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  ContractListResponseState,
} from "../../types/components/contractDetailsTypes";
import axios, { AxiosResponse } from "axios";
import {  
  getContractList,  
} from "../../networking/apiEndpoints";
import { ContractsFilterModel } from "../../types/components/contractFilterType";

const initialState: ContractListResponseState = {
  contractListResponse: [],
  loading: false,
  error:null,  
  responseStatus: 200,
  currentPage: 1,
  totalPages: 0,        
  totalRecords: 0,
};
export const fetchContractList = createAsyncThunk<
  AxiosResponse,
  ContractsFilterModel,
  {
    rejectValue: AxiosResponse;
  }
>(
  "contractList/fetch",
  async (contractsFilterModelData: ContractsFilterModel, thunkApi) => {
      const token = localStorage.getItem('token');
      const response = await axios.post(`${getContractList}`, contractsFilterModelData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        validateStatus: function (status) {
          return status < 500;
        },
      });
      if (response.status === 401) {
        return thunkApi.rejectWithValue(response);
      }
      return response;    
      
  }
);



export const contractListSlice = createSlice({
  name: "contractlist",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchContractList.pending, (state) => {
      state.loading = true;
      state.error = '';
    })
    .addCase(fetchContractList.fulfilled, (state, action) => {    
      state.contractListResponse = action.payload.data.customContractList;
      state.loading = false;
      
      // state.currentPage = action.payload.data.currentPage;
      // state.totalPages = action.payload.data.totalPages;
      state.totalRecords = action.payload.data.totalRecords;
    }) 
    .addCase(fetchContractList.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message ?? '';
      if (action.payload !== undefined) {
        state.responseStatus = action.payload.status;
    }
    });   
  },
});

export default contractListSlice.reducer;
