import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios, { AxiosResponse } from "axios";
import { ResponseModelState } from "../../types/components/responseModel";
import { reanalyzeStatusUpdate } from "../../networking/apiEndpoints";
import { ReanalyzeContractsPayload } from "../../types/pages/getPreProcessModel";

const initialState: ResponseModelState = {
    responseModel:{
        status:false,
        message:"",
        result: null
     },
    responseStatus: 200
}

export const reanalyzeScreeningContract = createAsyncThunk<
AxiosResponse, ReanalyzeContractsPayload,
{
rejectValue:AxiosResponse
}>(
    "reanalyzeScreeningContracts/post",
    async (reanalyzeContracts, thunkApi) => {
        var token = localStorage.getItem('token');     
        const response = await axios.post(reanalyzeStatusUpdate, reanalyzeContracts, {
        headers:{"Content-Type": "application/json",Authorization:`Bearer ${token}`},validateStatus: function(status){return status < 500;}
        })
        if(response.status === 401)
        {
          return thunkApi.rejectWithValue(response);
        }  
    return response;    
    }
);

export const reanalyzeScreeningContractSlice = createSlice({
    name: "reanalyzeScreeningContracts",
    initialState,
    reducers: {
    },
    extraReducers: (builder) => {
        builder
            .addCase(reanalyzeScreeningContract.fulfilled, (state, action) => {
                state.responseModel = action.payload.data; 
            })
            .addCase(reanalyzeScreeningContract.rejected, (state, action) => {
                if(action.payload !== undefined)
                    {
                      state.responseStatus = action.payload.status;
                    }
            });
    },
});
export default reanalyzeScreeningContractSlice.reducer;