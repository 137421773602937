import React, { useCallback, useEffect, useRef } from 'react';
import { activityEvents } from '../utilities/Constants';
import { refreshTokenApi } from "../redux/features/refreshTokenSlice";
import { setActiveUserNbf } from '../redux/UserSlice/setUserInfoSlice';
import { RootState, useAppDispatch } from '../redux/store';
import { useSelector } from 'react-redux';
import { getDecodedTokenPayload } from '../utilities/getPermissionsFromToken';

const InactivityHandler: React.FC = () => {
  const dispatch = useAppDispatch();
  const nbf = useSelector((state: RootState) => state.userInformation.nbf);
  const inactivityTimeout = useRef<NodeJS.Timeout | null>(null);
  const sessionTimeout = useRef<NodeJS.Timeout | null>(null);
  const sessionExpiryTime = useRef<number>(0);

  const clearAllTimeouts = useCallback(() => {
    if (inactivityTimeout.current) clearTimeout(inactivityTimeout.current);
    if (sessionTimeout.current) clearTimeout(sessionTimeout.current);
  }, []);

  const logoutUser = useCallback(() => {
    try {
      localStorage.clear();
      sessionStorage.clear();
      window.location.href = '/';
    } catch (error) {
      console.error('Error during auto-logout:', error);
    }
  }, []);

  const resetInactivityTimeout = useCallback(() => {
    if (inactivityTimeout.current) {
      clearTimeout(inactivityTimeout.current);
    }

    inactivityTimeout.current = setTimeout(() => {
      // User has been inactive for 15 minutes
      logoutUser();
    }, 15 * 60 * 1000);
  }, [logoutUser]);

  const attemptTokenRefreshRef = useRef<() => Promise<void>>();

  attemptTokenRefreshRef.current = async () => {
    try {
      const accessToken = localStorage.getItem('token');
      const refreshToken = localStorage.getItem('refreshToken');

      if (!accessToken || !refreshToken) {
        logoutUser();
        return;
      }

      const tokenModel = {
        accessToken,
        refreshToken,
      };

      const refreshResponse = await dispatch(refreshTokenApi(tokenModel)).unwrap();

      if (refreshResponse && refreshResponse.status) {
        const newAccessToken = refreshResponse.result.accessToken;
        const newRefreshToken = refreshResponse.result.refreshToken;

        // Update token and refreshToken
        localStorage.setItem('token', newAccessToken);
        localStorage.setItem('refreshToken', newRefreshToken);

        // Decode new access token to get nbf and exp
        const decodedToken = getDecodedTokenPayload();
        if (decodedToken) {
          const { nbf, exp } = decodedToken;
          if (nbf) {
            dispatch(setActiveUserNbf(nbf.toString()));
          }
          if (exp) {
            sessionExpiryTime.current = exp * 1000;
          }

          initializeTimeouts();
        } else {
          logoutUser();
        }
      } else {
        logoutUser();
      }
    } catch (error) {
      console.error('Error during token refresh:', error);
      logoutUser();
    }
  };

  const attemptTokenRefresh = useCallback(() => {
    if (attemptTokenRefreshRef.current) {
      attemptTokenRefreshRef.current();
    }
  }, []);

  const initializeTimeouts = useCallback(() => {
    clearAllTimeouts();

    const decodedToken = getDecodedTokenPayload();
    if (decodedToken) {
      const { nbf, exp } = decodedToken;

      if (nbf && exp) {
        dispatch(setActiveUserNbf(nbf.toString()));

        sessionExpiryTime.current = exp * 1000;

        const currentTime = Date.now();
        const remainingSessionTime = sessionExpiryTime.current - currentTime;
        const bufferTime = 1 * 60 * 1000; // 1 minute buffer to refresh

        if (remainingSessionTime <= bufferTime) {
          attemptTokenRefresh();
        } else {
          sessionTimeout.current = setTimeout(() => {
            attemptTokenRefresh();
          }, remainingSessionTime - bufferTime);
          resetInactivityTimeout();
        }
      } else {
        logoutUser();
      }
    } else {
      logoutUser();
    }
  }, [attemptTokenRefresh, resetInactivityTimeout, clearAllTimeouts, dispatch, logoutUser]);

  useEffect(() => {
    activityEvents.forEach((eventName) => {
      window.addEventListener(eventName, resetInactivityTimeout);
    });

    initializeTimeouts();

    return () => {
      activityEvents.forEach((eventName) => {
        window.removeEventListener(eventName, resetInactivityTimeout);
      });
      clearAllTimeouts();
    };
  }, [resetInactivityTimeout, initializeTimeouts, clearAllTimeouts]);

  return null;
};

export default InactivityHandler;