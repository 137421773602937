import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios, { AxiosResponse } from "axios";
import { addContractTerm, editContractTerm, duplicateCheckContractTerm } from "../../networking/apiEndpoints";
import { ContractTermModelState } from "../../types/components/contractTermModel";
import { DuplicateOrderNo } from "../../utilities/Constants";

const initialState: ContractTermModelState = {
    contractTermModel: [],
    isContrcatSaving: false,
    isDuplicateContract: false,
    showAlert: false,
    responseStatus: 200
};

// Add Contract Term
export const addContractTermFunction = createAsyncThunk<
    AxiosResponse | any,
    any,
    {
        rejectValue: AxiosResponse;
    }>(
    "addContractTerm/post",
    async (formData: any, thunkApi) => {
        var token = localStorage.getItem('token');
        const response = await axios.post(addContractTerm, formData, {
            headers: { "Content-Type": "application/json", Authorization: `Bearer ${token}` }, validateStatus: function (status) { return status < 500; }
        });
        if (response.status === 401) {
            return thunkApi.rejectWithValue(response);
        }
        return response.data;
    }
);
// Update Contract Term
export const updateContractTermFunction = createAsyncThunk<
                    AxiosResponse | any,
                    any,
                    {
                        rejectValue: AxiosResponse;
                    }>(
    "updateContractTerm/post",
    async (formData: any, thunkApi) => {
            var token = localStorage.getItem('token');
            const response = await axios.put(editContractTerm, formData, {
                headers: { "Content-Type": "application/json", Authorization: `Bearer ${token}` }
            });
            if (response.status === 401) {
                return thunkApi.rejectWithValue(response);
            }
            return response.data;
    }
);

export const isContractTermExists = createAsyncThunk<
AxiosResponse | any,
any,
{
    rejectValue: AxiosResponse;
}>(
    "checkDuplicateContractTerm/post",
    async (formData: any,thunkApi) => {
       
            var token = localStorage.getItem('token');
            const response = await axios.post(duplicateCheckContractTerm, formData, {
                headers: { "Content-Type": "application/json", Authorization: `Bearer ${token}` }
            });
            if (response.status === 401) {
                return thunkApi.rejectWithValue(response);
            }
            return response.data;       
    }
);

export const addEditContractTermSlice = createSlice({
    name: "addEditContractTerm",
    initialState,
    reducers: {
        hideAlert(state) {
            state.showAlert = false;
        },
        showAlert(state) {
            state.showAlert = true;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(addContractTermFunction.fulfilled, (state, action) => {
            state.contractTermModel = action.payload;
            if(action.payload !== undefined)
                {
                  state.responseStatus = action.payload.status;
                }
            state.isContrcatSaving = false;
            if(action.payload !== DuplicateOrderNo)
                {
                    state.showAlert = true;
                }
        });
        builder.addCase(addContractTermFunction.pending, (state, action) => {
            state.isContrcatSaving = true;
            state.showAlert = false;
        });
        builder.addCase(addContractTermFunction.rejected, (state, action) => {
            state.isContrcatSaving = false;
            state.showAlert = false;
        });
        builder.addCase(updateContractTermFunction.fulfilled, (state, action) => {
            state.contractTermModel = action.payload;
            if(action.payload !== undefined)
                {
                  state.responseStatus = action.payload.status;
                }
            state.isContrcatSaving = false;
            state.showAlert = true;
        });
        builder.addCase(updateContractTermFunction.pending, (state, action) => {
            state.isContrcatSaving = true;
            state.showAlert = false;
        });
        builder.addCase(isContractTermExists.fulfilled, (state, action) => {
            state.isDuplicateContract = action.payload;
            if(action.payload !== undefined)
                {
                  state.responseStatus = action.payload.status;
                }
            state.isContrcatSaving = false;
        });
        builder.addCase(isContractTermExists.pending, (state, action) => {
            state.isContrcatSaving = true;
        });
    },
});
export const { showAlert, hideAlert } = addEditContractTermSlice.actions;
export default addEditContractTermSlice.reducer;