import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Button from "../../../components/cst/Button";
import SyncOutlinedIcon from '@mui/icons-material/SyncOutlined';
import { MODULE_SYNCED_SUCCESSFULLY, Success, Error, FailToSyncSalesMarketingData } from "../../../utilities/Constants";
import { useFlashMessage } from "../../../redux/flashMessage/flashMessageHook";
import { getPermissionsFromToken } from "../../../utilities/getPermissionsFromToken";
import { SyncDataDashboard  } from "../../../utilities/PermissionConstants";
import { addEditCompanies } from "../../../redux/features/rras/addEditCompaniesSlice";
import Handle401Status from "../../../types/components/handleApiReasponseStatus";
import { useAppDispatch, useAppSelector } from "../../../redux/store";
import { upsertCompaniesModel } from "../../../types/components/rass/upsertCompaniesModel";
import { upsertCompanyDetailsModel } from "../../../types/components/rass/upsertCompanyDetailsModel";
import { addEditCompanyDetails } from "../../../redux/features/rras/addEditCompanyDetailsSlice";
import { getSeagageLastSyncInfo, getSeagageLastSyncInfoSlice } from "../../../redux/features/rras/getSeagageLastSyncInfoSlice";
import { UTCToLocalTimeZone } from "../../../utilities/CommonFunctions";
import { getAllTenantApi } from "../../../redux/features/getAllTenantSlice";
const SalesMarketingDataSync = () => { 
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [syncText, setSyncText] = useState('Sync');
    const [syncDatetime, setSyncDatetime] = useState<string>('');
    const { showFlashMessage } = useFlashMessage();
    const allTenants = useAppSelector((state) => state.getAllTenant.responseModel.result);
    const tenantsArray = Array.isArray(allTenants) ? allTenants : [allTenants];


    const handleDataSyncAction = () => {
        setSyncText('Syncing...');
        var tenantId = localStorage.getItem("tenantId");
        var selectedTenantName = "";
        if(allTenants !== null && allTenants !== undefined)
        {
            var selectedTenant = tenantsArray.filter(x=>x.id === tenantId);
            selectedTenantName = selectedTenant[0].name;
        }

        const upsertCompaniesModel: upsertCompaniesModel = {
          companyId: 0,
          tenantId: tenantId ?? "",
          tenantName: selectedTenantName,
          syncType: "M",
        }
    
        try {
            dispatch(addEditCompanies(upsertCompaniesModel)).then((response) => {
                if (response.payload?.status === 401) {
                    setSyncText('Sync');
                    Handle401Status(navigate, window);
                }
                if (!response.payload) {
                    setSyncText('Sync');
                    showFlashMessage(FailToSyncSalesMarketingData, Error, 3000);
                } 
                else if (response.payload.status) 
                {
                    showFlashMessage(MODULE_SYNCED_SUCCESSFULLY, Success, 3000);
                    setSyncText('Sync');
                    const syncedDate : string = UTCToLocalTimeZone(response.payload.result.toString()) ?? "";
                    dispatch(getAllTenantApi());
                    setSyncDatetime(syncedDate);
                } 
                else 
                {
                    setSyncText('Sync');
                    showFlashMessage(FailToSyncSalesMarketingData, Error, 3000);
                }
            });
        } 
        catch (error) 
        {
            setSyncText('Sync');
        }
    }

    const [syncDataDashboardPermissionNoAccess, setSyncDataDashboardPermissionNoAccess] = useState<boolean>(true);
    useEffect(()=>{
        const userPermissions = getPermissionsFromToken();
        setSyncDataDashboardPermissionNoAccess(userPermissions.indexOf(SyncDataDashboard) == -1); 
      },[])

      useEffect(() => {
        dispatch(getSeagageLastSyncInfo("")).then((response) => {
            if (response.payload) {
                if(response.payload.status && response.payload.result.lastSyncDateTime !== null && response.payload.result.lastSyncDateTime  !== undefined)
                {
                    const syncedDate : string = UTCToLocalTimeZone(response.payload.result.lastSyncDateTime.toString()) ?? "";
                    setSyncDatetime(syncedDate);
                }
                else
                {
                    setSyncDatetime("Not yet synced");
                }
            }
            else
            {
                setSyncDatetime("Not yet synced");
            }
          });
      }, []);

return(
    <div className="container">
        <div className="page-title">
            <h1>Sales & Marketing Data Sync</h1>
            <Button
                className="sales-marketing-data-sync"
                type="contained"
                text={syncText}
                startIcon={<SyncOutlinedIcon className="SyncOutlinedIcon" />}    
                onClick={handleDataSyncAction}    
                disabled = {syncDataDashboardPermissionNoAccess}
            ></Button>
        </div>
        <div className="text-align-right">
            <span className="sync-box">
                <b className="float-left">Last Sync:</b> 
                <span className="sync-value">{syncDatetime}</span>
            </span>
        </div>
    </div>
    )
}
export default SalesMarketingDataSync