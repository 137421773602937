import { createSlice,  createAsyncThunk } from "@reduxjs/toolkit";
import { DeleteEmailFollowupType } from "../../../types/components/rass/deleteEmailFollowupType"; 
import axios from "axios";
import { deleteEmailFollowup } from "../../../networking/apiEndpoints";

const initialState: DeleteEmailFollowupType = {
  isDeleted : false ,
  isLoading :false   
};
export const deleteEmailFollowUpById  = createAsyncThunk(
    "deleteemailfollowup/delete",
    async (id : string) => {
        try {          
          var token = localStorage.getItem('token');     
          var tenantId = localStorage.getItem('tenantId');           
          const response = await axios.delete(deleteEmailFollowup+ "/" + id + "/" + tenantId, {
          headers:{"Content-Type": "application/json", Authorization:`Bearer ${token}`}
        });              
        return response.status;
      } catch (err) {
        console.log(err);
      }
    }
  );
  
  export const deleteEmailFollowUpSlice = createSlice({
    name: "deleteemailfollowup",
    initialState,
    reducers: {},
    extraReducers: (builder) => {      
      builder.addCase(deleteEmailFollowUpById.fulfilled, (state, action ) => {
        
        if(action.payload === 200)
        { 
          state.isDeleted  = true;
          state.isLoading = false;        
        }
        else{
            state.isDeleted = false;
            state.isLoading = false;  
        }
      });
        builder.addCase(deleteEmailFollowUpById.pending, (state, action) => {
            state.isLoading = true;
        })
      },
  });
  
  export default deleteEmailFollowUpSlice.reducer;