import { useAppDispatch, useAppSelector } from "../../../redux/store";
import { useEffect, useState } from "react";
import Button from "../../../components/cst/Button";
import { useLocation } from "react-router-dom";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import MoveUpIcon from '@mui/icons-material/MoveUp';
import { Grid, Paper } from "@mui/material";
import { pdfjs } from "react-pdf";
import PdfViewByAPI from "../../../components/cst/PdfViewer/PdfView";
import CircularProgress from "@mui/material/CircularProgress";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { useNavigate } from "react-router-dom";
import Divider from "@mui/material/Divider";
import { fetchPreProcessingContractDetails } from "../../../redux/features/preProcessingContractDetailsSlice";
import { UTCToLocalTimeZone } from "../../../utilities/CommonFunctions";
import { getTenantName } from "../../../redux/features/getTenantNameById";
import { fetchContractFileByStream } from "../../../redux/features/getContractFileSlice";
import Handle401Status from "../../../types/components/handleApiReasponseStatus";

const isProduction = process.env.NODE_ENV === "production";
pdfjs.GlobalWorkerOptions.workerSrc = isProduction
  ? "/pdf.worker.min.js"
  : new URL("pdfjs-dist/legacy/build/pdf.worker.min.js", import.meta.url).toString(); 

export default function PreProcessingContractDetail() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const {preProcessingContractDetails, isloading} = useAppSelector(
    (state) => state.preprocessingcontractdetails
  );
  const [tenantName, setTenantName] = useState<string>("");
  const isContractDownloading = useAppSelector(
    (state) => state.getFilebystream.IsLoading
  );
  const dispatch = useAppDispatch();
  useEffect(() => {
    fetchPreProcesingContractDetailsById(); 
  }, []);
  const fetchPreProcesingContractDetailsById = async () => {
    await dispatch(fetchPreProcessingContractDetails(state.id));
    var tenantId = localStorage.getItem("tenantId");
    dispatch(getTenantName(tenantId!)).then((response) => {
      setTenantName(response.payload?.result);
    });
  };
  const updatedBy = preProcessingContractDetails.updatedBy;
  const updatedOn = preProcessingContractDetails.updatedOn;
  const docTitle = preProcessingContractDetails.title;
  const screeningTermTags = preProcessingContractDetails.screeningTermTags;
  const screeningFieldDetails = preProcessingContractDetails.screeningFieldDetails;
  const dispatchForFile = useAppDispatch();
  const DownloadContractFile = async () => {
    try {
      const fileName = preProcessingContractDetails.path;
     
      await dispatchForFile(fetchContractFileByStream(fileName)).then(
        (response) => {
          if (response.payload?.status === 401) {
            Handle401Status(navigate, window);
          }
          if (response.payload) {
            const url = URL.createObjectURL(response.payload.data);
            const link = document.createElement("a");
            link.href = url.toString();
            link.download = preProcessingContractDetails.fileName;
            link.click();
            window.URL.revokeObjectURL(url.toString());
          }
        }
      );
    } catch (error) {
      console.error("Error Downloading pre processing Contract File", error);
      return null;
    }
  };

  const contractDetailsData = (
    <div className="contractpage">
      <Divider />
      <div className="header-with-buttons">
        <Button
          className=""
          type="text"
          text="Download PDF"
          startIcon={<FileDownloadIcon />}
          onClick={() => DownloadContractFile()}
          disabled={false}
        ></Button>
      </div>
      {isContractDownloading && (
        <div className="loader-center">
          <CircularProgress className="progress-loading" />
        </div>
      )}
      <Grid container spacing={0} className="contract-detail-content">
        <Grid item xs={8}>
          <div>
            {state.id === preProcessingContractDetails.screeningContractId &&
              preProcessingContractDetails.fileName &&
              preProcessingContractDetails.fileName.length > 0 &&
              tenantName !== "" && (
                <PdfViewByAPI
                  fileName={
                    preProcessingContractDetails.path
                  }
                />
              )}
          </div>
        </Grid>
        <Grid item xs={4}>
          <div className="contractDetailsRightWrapper preProcessingContractDetailsRightWrapper">
            {!isloading ? (
              <div className="contract-details">
                <h3 className="detail-sub-heading">Details</h3>
                <div className="value-row">
                  <h5 className="value-title">Document Title</h5>
                  <div className="term-value">{docTitle}</div>
                </div>
                <div className="grid-container">
                  <div className="detail-col">
                    <div className="value-row">
                      <h5 className="value-title">Modified By</h5>
                      <div className="term-value">{updatedBy}</div>
                    </div>
                  </div>
                  <div className="detail-col">
                    <div className="value-row">
                      <h5 className="value-title align-right">Modified On</h5>
                      <div className="term-value align-right">
                        {UTCToLocalTimeZone(
                          updatedOn === null ? "" : updatedOn.toString()
                        )}
                      </div>
                    </div>
                  </div>
                </div>              
                <div className="value-row">
                  <h5 className="value-title">Document Tags</h5>
                  <div className="term-value">{screeningTermTags}</div>
                </div>
                <h3 className="detail-sub-heading">Screening Terms</h3>
                 {screeningFieldDetails.map((item) => (
                      <div key={item.id} className="value-row">
                          <h5 className="value-title">{item.name}</h5>
                          <div className="term-value">{item.value}</div>
                      </div>
                  ))}
              </div>
            ) : (<div className="loader-center">
              <CircularProgress className="progress-loading" />
            </div>)}
          </div>
        </Grid>
      </Grid>
    </div>
  );

  return (
    <div className="container contractDetailsContainer">
      <div className="mb heading-no-button">
      <h1>Pre-Processing Contract Details</h1>
      </div>
      <div className="backbutton" onClick={() => navigate("/pre-processing-contracts")}>
        <ArrowBackIosIcon /> Back
      </div>
      <Paper className="main-paper" sx={{ height: "100vh", overflow: "hidden" }}>
        {contractDetailsData}
      </Paper>
    </div>
  );
}
