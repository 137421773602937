import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { UsernameCheck } from '../../networking/apiEndpoints';

export const isUsernameExist = createAsyncThunk(
    'isusernameexist',
    async (username: string) => {
        try {
            const response = await axios.get(UsernameCheck+'/'+ username.trim(), {
                headers:{
                    "Content-Type": "application/json",
                    Authorization:`Bearer ${localStorage.getItem('token')}`},
                    validateStatus: function(status){return status < 500;
                    }
              }) ;
            return response.data.status;
        } catch (error) {
            throw error;
        }
    }
);

const isUsernameExistSlice = createSlice({
    name: 'isUsernameExist',
    initialState: {
        usernameExist: false,
        isLoading: false
    },
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(isUsernameExist.fulfilled, (state, action) => {
            state.isLoading = false;
            state.usernameExist = action.payload;
        });
        builder.addCase(isUsernameExist.pending, (state, action) => {
            state.isLoading = true;
        });
    },
});
export default isUsernameExistSlice.reducer;
