import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios, { AxiosResponse } from "axios";
import {
    getMessageCreatorAPI,
} from "../../../../networking/apiEndpoints";
import { MessageCreatorResponseState } from "../../../../types/components/rass/messageCreatorModel";

const initialState: MessageCreatorResponseState = {
    messageCreatorListResponse:{
    status:false,
    message:"",
    result: null,    
 } ,
  loading: false,
  error:null,  
  responseStatus: 200
};
export const fetchMessageCreatorList = createAsyncThunk<
AxiosResponse|any,
  any,
  {
    rejectValue: AxiosResponse;
  }
>(
  "messageCreatorList/fetch",
  async (searchMessageCreatorParams: { tenantId?: string }, thunkApi) => {
    var token = localStorage.getItem('token');
    searchMessageCreatorParams.tenantId = localStorage.getItem('tenantId')??"";      
      const response = await axios.post(`${getMessageCreatorAPI}`, searchMessageCreatorParams, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        validateStatus: function (status) {
          return status < 500;
        },
      });      
      if (response.status === 401) {
        return thunkApi.rejectWithValue(response);
      }
      return response.data;    
  }
);

export const getMessageCreatorSlice = createSlice({
  name: "messagelist",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchMessageCreatorList.pending, (state) => {
      state.loading = true;
      state.error = '';
    })
    .addCase(fetchMessageCreatorList.fulfilled, (state, action) => {    
      state.messageCreatorListResponse = action.payload?.data;
      state.loading = false;
    }) 
    .addCase(fetchMessageCreatorList.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message ?? '';
      if (action.payload !== undefined) {
        state.responseStatus = action.payload.status;
    }
    });   
  },
});

export default getMessageCreatorSlice.reducer;
