import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios, { AxiosResponse } from "axios";
import { getModuleListSeagage } from "../../../../networking/apiEndpoints";
import { IResponseModelState } from "../../../../types/components/responseModel";

const initialState: IResponseModelState = {
    responseModel:{
       status:false,
       message:"",
       result: null
    } ,
    loading: false,
    error:"",
    showAlert:false,
    responseStatus: 200
};

export const getModuleListApi = createAsyncThunk<
AxiosResponse,void,
{
rejectValue:AxiosResponse
}
>(
    "getModuleList/get",
    async (_arg, thunkApi) => {
            var tenantId = localStorage.getItem("tenantId");
            const response = await axios.get(getModuleListSeagage + '/' + tenantId, {
                headers:{"Content-Type": "application/json",Authorization:`Bearer ${localStorage.getItem('token')}`},validateStatus: function(status){return status < 500;}
            })
            if(response.status === 401)
                {
                  return thunkApi.rejectWithValue(response);
                }  
            return response;
    }
);

export const getModuleListSlice = createSlice({
    name: "getModuleList",
    initialState,
    reducers: {       
    },
    extraReducers: (builder) => {
        builder
        .addCase(getModuleListApi.pending, (state, action) => {
            state.error = '';
            state.loading = true;
            state.showAlert = false;
        })
        .addCase(getModuleListApi.fulfilled, (state, action) => {
            state.responseModel = action.payload.data; 
            state.responseStatus = action.payload.status;    
            state.loading = false;                   
        })
        .addCase(getModuleListApi.rejected, (state, action) => {
            if(action.payload !== undefined)
              {
                state.responseStatus = action.payload.status;
              }
              state.error = action.error.message ?? '';
              state.loading = false;
              state.showAlert = false;
          });        
    },
});

export default getModuleListSlice.reducer;