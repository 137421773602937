import { jwtDecode, JwtPayload } from "jwt-decode";
import { decrypt, encrypt } from ".";

interface CustomJwtPayload extends JwtPayload {
  Permissions?: string[];
  nbf?: number;
  exp?: number;
}

// Private generic function to get and decode the token - to avoid duplicate code
const getDecodedToken = (): CustomJwtPayload | null => {
  try {
    const token = localStorage.getItem('token');
    if (!token) {
      return null;
    }
    const decryptedToken = decrypt(token);
    const decodedToken = jwtDecode<CustomJwtPayload>(decryptedToken);
    return decodedToken;
  } catch (error) {
    console.log('Invalid token:', error);
    return null;
  }
};

// Function to get permissions from the token
export const getPermissionsFromToken = (): string[] => {
  const decodedToken = getDecodedToken();
  if (decodedToken && decodedToken.Permissions) {
    return decodedToken.Permissions;
  }
  return [];
};

// Function to get the full decoded token payload
export const getDecodedTokenPayload = (): CustomJwtPayload | null => {
  return getDecodedToken();
};