import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Avatar from "@mui/material/Avatar";
import Divider from '@mui/material/Divider';
import {
  AppBar,
  Toolbar,
  Button,
  FormControl,
  InputLabel,
  Menu,
  MenuItem,
} from "@mui/material";
import { SyntheticEvent, useEffect } from "react";
import { useState } from "react";
import {
  Link,
  Navigate,
  Route,
  useLocation,
  useNavigate,
} from "react-router-dom";
import SelectBox from "../../components/cst/SelectBox";
import { persistor, useAppDispatch, useAppSelector } from "../../redux/store";
import {
  ResponseWithSelectedModelState,
  selectedTenant,
} from "../../redux/features/getAllTenantSlice";
import React from "react";
import * as icons from "@mui/icons-material";
import {
  PreProcessing,
  Contracts,
  UserList,
  FollowUpEmailCreator,
  userProfileMenu,
  ContractTypes,
  ScreeningTerms,
  ContractTerms, 
  EmailTemplates, 
  SummaryTemplates,
  systemAdminRoleName,
  AIMessages,
  CSTPaths,
  REGPaths,
  SharedPaths,
  RolesAndPermissions,
} from "../../utilities/Constants";
import KeyboardArrowRightOutlinedIcon from '@mui/icons-material/KeyboardArrowRightOutlined';
import Person2OutlinedIcon from "@mui/icons-material/Person2Outlined";
import WarningAmberRoundedIcon from "@mui/icons-material/WarningAmberRounded";
import RadioButtonCheckedOutlinedIcon from '@mui/icons-material/RadioButtonCheckedOutlined';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import TabsComponent from "../../components/Tabs";
import { setActiveMenuItem } from "../../redux/features/getMenuSlice";
import { useModuleSwitcher } from "../../redux/features/moduleSwitcher/moduleSwicherHook";
import { usePermissionNavList } from "../LeftNav/permissionNavHook";
import { useREGModuleMessage } from "../../redux/features/rras/regModuleMessage/regModuleMessageHook";
import { useAuthSetting } from "../../redux/UserSlice/setAuthHook";
import { useLoggedInUserInformation } from "../../redux/UserSlice/setUserInfoHook";
import { getIsCST, getIsRRAS } from "../../utilities";
import { set } from "date-fns";
import { useProfileImage } from "../../redux/UserSlice/setProfileImageHook";
import {   
  ViewMyProfilePermission, 
  ChangePasswordPermission
} from "../../utilities/PermissionConstants";
import { getPermissionsFromToken } from "../../utilities/getPermissionsFromToken";
interface userRoleDataInterface {
  status?: boolean;
  result?: Array<string>;
  message?: string;
}
interface userInfoInterface {
  Name?: string;
  Role?: string;
  Permissions?: string;
  DisplayName?: string;
}
interface allTenantDataResultInterface {
  id: string;
  name: string;
  isCST?: boolean;
  isRRAS?: boolean;
}
interface allTenantDataInterface {
  status?: boolean;
  result: Array<allTenantDataResultInterface>;
  message?: string;
}
interface SinglePortco {
  id: string;
  name: string;
}
export default function MenuAppBar() {
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(
    null
  );
  const {clearREGModule} = useREGModuleMessage();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const {setAuth} =  useAuthSetting();
  const {Role, userImage, Id, TenantId} = useLoggedInUserInformation();
  const {getProfileImage} = useProfileImage();
  const [userInfo, setUserInfo] = useState<userInfoInterface>();
  const [activeIndex, setActiveIndex] = useState();
  const [selectProcoMenu, setSelectProcoMenu] = useState<boolean>(false);
  const [userName, setUserName] = useState<string>();
  const [userRoles, setUserRole] = useState<string>();
  const [viewMyProfilePermissionNoAccess, setViewMyProfilePermissionNoAccess] = useState<boolean>(true);
  const [changePasswordPermissionNoAccess, setChangePasswordPermissionNoAccess] = useState<boolean>(true);
  const [newPermissionArray, setNewPermissionArray] = useState<string[]>([]);
  const [getSelectedTenant, setGetSelectedTenant] = useState<any>();
  const {setActiveTab, activeTabIndex} = useModuleSwitcher();
  const handleTabChange = (event: SyntheticEvent, newValue: number) => {
    setActiveTab(newValue)
  };

  const [leftMargin, setLeftMargin] = useState("");
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };
  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  const logout = () => {
    persistor.purge();
    clearREGModule();
    setAuth(0);
    localStorage.clear();
    sessionStorage.clear(); 
    navigate("/"); 
  }
  const selectPortco = () => {
    setSelectProcoMenu(true);
  }

  const closeSelectPortco = () => {
    setSelectProcoMenu(false);
  }

  const handleMenuItemClick = (profileMenu: any, onClick: any) => {
    if (profileMenu == "My Profile") {
      navigate(SharedPaths.pathProfile);
    } else if (profileMenu == "Change Password") {
      navigate(SharedPaths.pathChangePassword);
    }
    handleCloseUserMenu();
  };
  const { LeftNavListREG, LeftNavListCST, navigateToVisibleItem } = usePermissionNavList();
  useEffect(() => {
    if (localStorage.getItem("userInfo") != undefined) {
      const userInfoString = JSON.parse(
        localStorage.getItem("userInfo") ?? ""
      ) as userInfoInterface;
      setUserInfo(
        JSON.parse(localStorage.getItem("userInfo") ?? "") as userInfoInterface
      );
      setUserName(userInfoString?.DisplayName);
      setUserRole(userInfoString?.Role);
    }
  }, []);

  const allTenant: ResponseWithSelectedModelState = useAppSelector((state) => {
    return state.getAllTenant;
  });
  const allTenantData = allTenant?.responseModel as allTenantDataInterface;
  let allTenantListData = allTenantData.result as SinglePortco[];
  if (allTenantData.result) {
    allTenantListData = allTenantListData.filter(function (
      val: allTenantDataResultInterface
    ) {
      return val.name.trim() !== "";
    });
    allTenantListData.sort((a: SinglePortco, b: SinglePortco) =>
      a.name > b.name ? 1 : b.name > a.name ? -1 : 0
    );
  }
  const loc = useLocation();
  useEffect(() => {
    document.querySelector("body")?.classList.remove("menu-active");
  }, [loc]);

  const removeIdFromPath = (str, startChar, endChar) => {
    const regex = new RegExp(`\\${startChar}(.*?)\\${endChar}`);
    const match = str.match(regex)
    return match ? match[0].substring(0, match[0].length - 1) : str
  } 

  useEffect(() => {
    if (loc.pathname === CSTPaths.pathContractDetails || loc.pathname === CSTPaths.pathPreProcessingContractDetails) {
      setLeftMargin("tab-margin");
    } else {
      setLeftMargin("");
    }

    const currentURL = removeIdFromPath(loc.pathname, '/', '/')
    
    // Using switch case to set the active menu item
    switch (currentURL) {  

      // CST menu item
      case "/":         
      dispatch(setActiveMenuItem(Contracts));    
      setActiveTab(0);   
      break;       
      
      case CSTPaths.pathContractDetails:
      dispatch(setActiveMenuItem(Contracts))
      setActiveTab(0);   
      break
      
      case CSTPaths.pathUploadContract:
      dispatch(setActiveMenuItem(Contracts))
      setActiveTab(0);
      break
      
      case CSTPaths.pathEditUploadedContract:
      dispatch(setActiveMenuItem(Contracts))
      setActiveTab(0);   
      break

      case CSTPaths.pathGetChildContractList:
      dispatch(setActiveMenuItem(Contracts))
      setActiveTab(0);   
      break

      case CSTPaths.pathPreProcessing:
      dispatch(setActiveMenuItem(PreProcessing))
      setActiveTab(0);   
      break

      case CSTPaths.pathPreProcessingContractDetails:
      dispatch(setActiveMenuItem(PreProcessing))
      setActiveTab(0);   
      break

      case CSTPaths.pathUsersFeedback:
      dispatch(setActiveMenuItem("Users Feedback"))
      setActiveTab(0);   
      break
      
      case CSTPaths.pathContractReports:
      dispatch(setActiveMenuItem("Contract Reports"))
      setActiveTab(0);   
      break
            
      case CSTPaths.pathContractTypes:         
      dispatch(setActiveMenuItem(ContractTypes)); 
      setActiveTab(0);   
      break; 

      case CSTPaths.pathAddContractType:
      dispatch(setActiveMenuItem(ContractTypes))
      setActiveTab(0);   
      break
      
      case CSTPaths.pathEditContractType:
      dispatch(setActiveMenuItem(ContractTypes))
      setActiveTab(0);   
      break

      case CSTPaths.pathContractTerms:
      dispatch(setActiveMenuItem(ContractTerms))
      setActiveTab(0);   
      break

      case CSTPaths.pathAddContractTerm:
      dispatch(setActiveMenuItem(ContractTerms))
      setActiveTab(0);   
      break

      case CSTPaths.pathEditContractTerm:
      dispatch(setActiveMenuItem(ContractTerms))
      setActiveTab(0);   
      break

      case CSTPaths.pathScreeningTerms:
      dispatch(setActiveMenuItem(ScreeningTerms))
      setActiveTab(0);   
      break

      case CSTPaths.pathAddScreeningTerm:
      dispatch(setActiveMenuItem(ScreeningTerms))
      setActiveTab(0);   
      break
      
      // REG menu item
      case REGPaths.pathMessages:         
      dispatch(setActiveMenuItem(AIMessages)); 
      setActiveTab(1);        
      break;   

      case REGPaths.pathAddMessage:         
      dispatch(setActiveMenuItem(AIMessages)); 
      setActiveTab(1);        
      break;   
      
      case REGPaths.pathFollowUpEmailCreator:         
      dispatch(setActiveMenuItem(FollowUpEmailCreator)); 
      setActiveTab(1);        
      break;   

      case REGPaths.pathCreateEmail:         
      dispatch(setActiveMenuItem(FollowUpEmailCreator)); 
      setActiveTab(1);        
      break;  

      case REGPaths.pathViewEmail:         
      dispatch(setActiveMenuItem(FollowUpEmailCreator)); 
      setActiveTab(1);        
      break;  
      
      case REGPaths.pathSummaryTemplates:         
      dispatch(setActiveMenuItem(SummaryTemplates)); 
      setActiveTab(1);        
      break;  
      
      case REGPaths.pathAddSummaryTemplate:         
      dispatch(setActiveMenuItem(SummaryTemplates)); 
      setActiveTab(1);        
      break;  

      case REGPaths.pathEditSummaryTemplate:  
      dispatch(setActiveMenuItem(SummaryTemplates)); 
      setActiveTab(1);        
      break;  
      
      case REGPaths.pathEmailTemplates:         
      dispatch(setActiveMenuItem(EmailTemplates)); 
      setActiveTab(1);        
      break;  

      case REGPaths.pathAddEmailTemplate:         
      dispatch(setActiveMenuItem(EmailTemplates)); 
      setActiveTab(1);        
      break;  

      case REGPaths.pathEditEmailTemplate:
      dispatch(setActiveMenuItem(EmailTemplates)); 
      setActiveTab(1);        
      break;  
      
      case REGPaths.pathSync:         
      dispatch(setActiveMenuItem("Sales & Marketing Data Sync")); 
      setActiveTab(1);        
      break;  

      // Shared menu item
      case SharedPaths.pathRolesAndPermissions:         
      dispatch(setActiveMenuItem(RolesAndPermissions));      
      break;   
      
      case SharedPaths.pathRoleAdd:         
      dispatch(setActiveMenuItem(RolesAndPermissions));       
      break;   

      case SharedPaths.pathRoleEdit:         
      dispatch(setActiveMenuItem(RolesAndPermissions));       
      break;   

      case SharedPaths.pathUserList:         
      dispatch(setActiveMenuItem(UserList)); 
      break;   

      case SharedPaths.pathAddUser:         
      dispatch(setActiveMenuItem(UserList)); 
      break;   

      case SharedPaths.pathEditUser:         
      dispatch(setActiveMenuItem(UserList)); 
      break;   

      case SharedPaths.pathChangePassword:
      dispatch(setActiveMenuItem(" ")); 
      break;  

      case SharedPaths.pathProfile:
      dispatch(setActiveMenuItem(" ")); 
      break; 
      
      case SharedPaths.pathEditProfile:
      dispatch(setActiveMenuItem(" ")); 
      break; 

      default: 
      break; 
    }
  }, [loc]);

  const toggleMenus = () => {
    document.querySelector("body")?.classList.toggle("menu-active");
  };
  const [selectedPortco, setSelectedPortco] = useState<string>(
    allTenant.selectedTenant
  );
  const [portcoName, setPortcoName] = useState<string>("");
  useEffect(() => {
    const userPermissions = getPermissionsFromToken();
    setViewMyProfilePermissionNoAccess(userPermissions.indexOf(ViewMyProfilePermission) == -1)
    setChangePasswordPermissionNoAccess(userPermissions.indexOf(ChangePasswordPermission) == -1)
    
    if (
      userRoles &&
      [systemAdminRoleName].indexOf(userRoles)
    ) {
      setSelectedPortco(allTenant.selectedTenant);
    }
    if(Id){
      getProfileImage({userId:Id, currentUser:true, tenantId: TenantId});
    }
  }, []);

  const handleChange = (event, tenantId: string, index: number) => {
    event.stopPropagation();
    setActiveIndex(getSelectedTenant)
    setSelectedPortco(tenantId);
    dispatch(selectedTenant(tenantId));
    localStorage.setItem("tenantId", tenantId);
    handleSelectedTenant();
    const getNewSelection = allTenantData.result.find(
      (el) => el.id === localStorage.getItem("tenantId")
    );
    setSelectProcoMenu(false); 
    setAnchorElUser(null);
    if (getNewSelection?.isRRAS && !getNewSelection?.isCST) {
      navigateToVisibleItem(LeftNavListREG);
      setActiveTab(1);
    } else {
      navigateToVisibleItem(LeftNavListCST);
      setActiveTab(0);
    }
  };

  const handleSelectedTenant = () => {
    if (allTenantData.result !== null) {
      const tenant = allTenantData.result.find(
        (el) => el.id === localStorage.getItem("tenantId")
      );
      setGetSelectedTenant(tenant);
    }
  };

  useEffect(() => {
    handleSelectedTenant();
  }, [getSelectedTenant]);

  const handleTabClick = (index: number, route?: string) => {
    if (index === 0) {
      navigateToVisibleItem(LeftNavListCST);
      setActiveTab(0);
    } else if (index === 1) {
      navigateToVisibleItem(LeftNavListREG);
      setActiveTab(1);
    }
  };
  const isTabsComponentDisplay = (): boolean =>{
    let userPermissionsIsCST = getIsCST();
    let userPermissionsIsRRAS = getIsRRAS();
    return userPermissionsIsCST && userPermissionsIsRRAS && getSelectedTenant?.isCST && getSelectedTenant?.isRRAS;
  };
  const routePortcoInfo = useLocation();

  useEffect(() => {
    if (Role === systemAdminRoleName){
      if (routePortcoInfo.pathname === REGPaths.pathFollowUpEmailCreator) {
        dispatch(setActiveMenuItem(FollowUpEmailCreator));
      } else if (routePortcoInfo.pathname === "/") {
        dispatch(setActiveMenuItem(Contracts));
      }
    }
  }, [portcoName]);

  const tabs = [
   { label: "CST", route: "/" },
    { label: "REG", route: REGPaths.pathFollowUpEmailCreator },
  ];
  useEffect(() => {
    const userPermissions = () => {
      const newPermissionArray: string[] = [];
     
      if (!viewMyProfilePermissionNoAccess){
        newPermissionArray.push("viewProfile")
      }
      if (!changePasswordPermissionNoAccess){
        newPermissionArray.push("changePassword")
      }
     return newPermissionArray
    };
   
    function getUserProfileMenu(userPermissions: string[]) {
      const menuItems: Record<string, string> = {
        "My Profile": "viewProfile",
        "Change Password": "changePassword",
      };
      return userProfileMenu.filter(item => userPermissions.includes(menuItems[item]));
    }
   
    const permissions = userPermissions();
    setNewPermissionArray(getUserProfileMenu(permissions)); 
  }, [viewMyProfilePermissionNoAccess, changePasswordPermissionNoAccess]);


  return (
    <Box sx={{ display: "flex" }} className="topNavContainer">
      <AppBar className="app-bar" component="nav" position="fixed" color="inherit" elevation={2}>
        <Toolbar sx={{ justifyContent: "end" }}>
          <Button
            className="menuToggle"
            onClick={toggleMenus}
            aria-label="Menu Toggle"
          >
            <span className="menuIcon" aria-hidden="true"></span>
          </Button>
              <div className="top-nav-tab">
                {isTabsComponentDisplay() && (
                  <TabsComponent
                    tabs={tabs}
                    value={activeTabIndex}
                    onChange={handleTabChange}
                    onTabClick={handleTabClick}
                    cssStyle={leftMargin}
                  ></TabsComponent>
                )}
              </div>
          <div className="topnav-profileimg">
            <Avatar
              aria-label="Profile Image"
              className="form-image-top-nav"
              src={userImage ?? "/static/images/avatar/2.jpg"}
            />
          </div>
          <div className="userDetails">
            <Typography variant="subtitle2" noWrap className="userNameWrap">
              {userName}
            </Typography>
            <Typography variant="body2" noWrap className="userRoleWrap">
              {userRoles}
            </Typography>
          </div>
          <span className={(anchorElUser === null) ? ' ' : 'open-user-menu'}> 
          <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
            <icons.ExpandMoreOutlined />
          </IconButton>
          </span>

          <Menu
            sx={{ mt: "45px" }}
            id="menu-appbar"
            className="header-profile-menu"
            anchorEl={anchorElUser}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={Boolean(anchorElUser)}
            onClose={handleCloseUserMenu}
          >
            {newPermissionArray.map((profileMenu: any) => (
              <MenuItem
                key={profileMenu}
                onClick={() =>
                  handleMenuItemClick(profileMenu, profileMenu.onClick)
                }
              >
                {profileMenu == "My Profile" && (
                  <Person2OutlinedIcon
                    fontSize="small"
                    sx={{ marginRight: 1 }}
                  />
                )}
                {profileMenu == "Change Password" && (
                  <WarningAmberRoundedIcon
                    fontSize="small"
                    sx={{ marginRight: 1 }}
                  />
                )}
                <Typography textAlign="center">{profileMenu}</Typography>
              </MenuItem>
            ))}
            {userRoles &&
            [systemAdminRoleName].indexOf(userRoles) !==
              -1 && (
                <MenuItem onMouseOver={selectPortco} onMouseOut={closeSelectPortco}>
                <RadioButtonCheckedOutlinedIcon fontSize="small" sx={{ marginRight: 1 }} /> Select Portco 
                <KeyboardArrowRightOutlinedIcon sx={{ marginLeft: 2 }} />
                {selectProcoMenu && (
                  <div className="select-portco-menu">
                    <div className="menu-item-container">
                      {allTenantListData && allTenantListData.map((item)=>
                      <MenuItem  
                      key={item.id}
                      selected={item.id === getSelectedTenant.id}
                      onClick={(event) => { handleChange(event, item.id, getSelectedTenant.id);setPortcoName(item.name);  }} 
                      className="select-portco-menu-item" >{item.name}
                      </MenuItem>)}
                    </div>
                  </div>
                )}
              </MenuItem>
              )}
            <Divider />
            <MenuItem onClick={logout} sx={{ marginBottom: 1 }}><LogoutOutlinedIcon fontSize="small" sx={{ marginRight: 1}} /> Logout</MenuItem>
          </Menu>
          
        </Toolbar>
      </AppBar>
    </Box>
  );
}
