import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios, { AxiosResponse } from "axios";
import { getFilterUsers } from "../../networking/apiEndpoints";
import { UserListModelState } from "../../types/components/userModel";
import { UsersFilterModel } from "../../types/components/userFilterModel";



const initialState: UserListModelState = {
    userListModel:{
      status:false,
      message:"",
      result: []    
   } ,
    loading: false,
    error:null,
    responseStatus: 200
  };


export const getUsersByTenantApi = createAsyncThunk<
AxiosResponse,
UsersFilterModel,
  {
  rejectValue:AxiosResponse
}
>(
    "getUsersByTenant/get",
    async (usersFilterModel: UsersFilterModel, thunkApi) => {  
      const response = await axios.post(`${getFilterUsers}`, usersFilterModel, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
        validateStatus: function (status) {
          return status < 500;
        },
      });
      if (response.status === 401) {
        return thunkApi.rejectWithValue(response);
      }
      return response;         
    }
);

export const getUsersByTenantSlice = createSlice({
    name: "getUsersByTenant",
    initialState,
    reducers: {       
    },
    extraReducers: (builder) => {
        builder.addCase(getUsersByTenantApi.pending, (state) => {
          state.loading = true;
          state.error = '';
        })
        .addCase(getUsersByTenantApi.fulfilled, (state, action) => {
          if(action.payload !== undefined)
          state.userListModel = action.payload.data;
          state.loading = false;
          if(action.payload !== undefined)
            {
              state.responseStatus = action.payload.status;
            }
        }) 
        .addCase(getUsersByTenantApi.rejected, (state, action) => {
          state.loading = false;
          state.error = action.error.message ?? '';
          if(action.payload !== undefined)
            {
              state.responseStatus = action.payload.status;
            }
        });   
      },
});

export default getUsersByTenantSlice.reducer;
