import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { getGeneratedMessageAPI } from "../../../../networking/apiEndpoints";
import { GenerateMessageRequestModel } from "../../../../types/components/rass/genrateMessageReqModel";

export const getGeneratedMessageDataApi = createAsyncThunk(
    "personaData/fetch",
    async (reqModel:GenerateMessageRequestModel, thunkApi) => {        
        const token = localStorage.getItem('token');
        try {
            const response = await axios.post(getGeneratedMessageAPI, reqModel, {
                headers:{"Content-Type": "application/json", Authorization:`Bearer ${token}`},validateStatus: function(status){return status < 500;}
            });
            if(response.status === 401)
                {
                return thunkApi.rejectWithValue(response);
            }
            return response.data;
        } catch (error) {
            console.log(error);
        }
    }
);
interface GeneratedMessageDataState {
    data: [] | undefined;
    loading: boolean;
    error: string | null;
    responseStatus:number;
}
const initialState: GeneratedMessageDataState = {
    data: [],
    loading: false,
    error: null,
    responseStatus: 200,
};
export const getGeneratedMessageDataSlice = createSlice({
    name: "getGeneratedMessageData",
    initialState,
    reducers: {
    },
    extraReducers: (builder) => {
        builder
            .addCase(getGeneratedMessageDataApi.pending, (state, action) => {
                state.error = '';
                state.loading = true;
            })
            .addCase(getGeneratedMessageDataApi.fulfilled, (state, action) => {
                state.data = action.payload;
                if(action.payload !== undefined)
                    {
                      state.responseStatus = action.payload.status;
                    }                
                state.loading = false;
            })
            .addCase(getGeneratedMessageDataApi.rejected, (state, action) => {
                state.error = action.error.message ?? '';
                state.loading = false;
            });
    },
});
export default getGeneratedMessageDataSlice.reducer;