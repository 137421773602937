import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios, { AxiosResponse } from "axios";
import { moveContracts } from "../../networking/apiEndpoints";
import { ResponseModelState } from "../../types/components/responseModel";
import { moveContractsPayload } from "../../types/pages/getPreProcessModel";

const initialState: ResponseModelState = {
    responseModel:{
        status:false,
        message:"",
        result: null
     },
    responseStatus: 200
}

export const movePreProcessedContracts = createAsyncThunk<
AxiosResponse,moveContractsPayload,
{
rejectValue:AxiosResponse
}>(
    "movePreProcessedContracts/post",
    async (movePreProcessedContracts, thunkApi) => {
        var token = localStorage.getItem('token');     
        const response = await axios.post(moveContracts,movePreProcessedContracts, {
        headers:{"Content-Type": "application/json",Authorization:`Bearer ${token}`},validateStatus: function(status){return status < 500;}
        })
        if(response.status === 401)
        {
          return thunkApi.rejectWithValue(response);
        }  
    return response;    
    }
);

export const movePreProcessedContractsSlice = createSlice({
    name: "movePreProcessedContracts",
    initialState,
    reducers: {
    },
    extraReducers: (builder) => {
        builder
            .addCase(movePreProcessedContracts.fulfilled, (state, action) => {
                state.responseModel = action.payload.data; 
            })
            .addCase(movePreProcessedContracts.rejected, (state, action) => {
                if(action.payload !== undefined)
                    {
                      state.responseStatus = action.payload.status;
                    }
            });
    },
});
export default movePreProcessedContractsSlice.reducer;