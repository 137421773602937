/* -----------------Meeting Page Permissions------------------------ */
export const AddNewMeetingPermission = "Add New Meeting";
export const ViewEmailDashboardPermission = "View Meetings Dashboard";
export const EditEmailAndSummaryPermission = "Edit Email & Summary";
export const DeleteEmailAndSummaryPermission = "Delete Email & Summary";
/* -----------------End of Meeting Page Permissions--------------------- */

/* -----------------Start REG Left Menu Permissions------------------------ */
export const NavREGMeetingDashboard = "View Meetings Dashboard";
export const NavREGSummaryTemplateDashboard = "View Summary Templates Dashboard";
export const NavREGEmailTemplateDashboard = "View Email Templates Dashboard";
export const NavREGUserDashboard = "View Users Dashboard";
export const NavREGRolesPermissionDashboard = "View Roles & Permissions Dashboard";
export const NavREGSalesAndMarkettingDataSync = "Sync Data Dashboard";
export const NavREGAIMessages = "View Messages Dashboard";
export const NAVREGUserProfileInformationPermission = "Profile Information Dashboard";
/* -----------------End REG Left Menu Permissions------------------------ */

/* -----------------Start CST Left Menu Permissions------------------------ */
export const NavCSTContractsDashboard = "View Contracts Dashboard";
export const NavCSTPreProcessingContractsDashboard = "View Pre-Processing Contracts Dashboard";
export const NavCSTContractsTypesDashboard = "View Contract Types Dashboard";
export const NavCSTContractsTermsDashboard = "View Contract Terms Dashboard";
export const NavCSTUserFeedbackReport = "Generate Users Feedback Report";
export const NavCSTGenerateContractsReport = "Generate Contracts Report";
export const NavCSTUsersDashboard = "View Users Dashboard";
export const NavCSTRolesPermissionDashboard = "View Roles & Permissions Dashboard";
export const NavCSTViewScreeningTermsDashboardPermission = "View Screening Terms Dashboard";
export const NAVCSTUserProfileInformationPermission = "Profile Information Dashboard";
/* -----------------End CST Left Menu Permissions------------------------ */

/* -----------------Summary Template Page Permissions------------------------ */
export const AddNewSummaryTemplatePermission = "Create Custom Summary Template";
export const ViewSummaryTemplateDashboardPermission = "View Summary Templates Dashboard";
export const EditSummaryTemplatePermission = "Edit Custom Summary Template";
export const DeleteSummaryTemplatePermission = "Delete Custom Summary Template";    
/* -----------------End of Summary Template Page Permissions--------------------- */

/* -----------------CST- Contract Type Page Permissions------------------------ */
export const AddNewContractTypePermission = "Create Contract Types";
export const ViewContractTypeDashboardPermission = "View Contract Types Dashboard";
export const EditContractTypePermission = "Edit Contract Types";
export const DeleteContractTypePermission = "Delete Contract Types";    
/* -----------------End of Contract Type Page Permissions--------------------- */

/* -----------------Email Template Page Permissions------------------------ */
export const CreateCustomEmailTemplatePermission = "Create Custom Email Template";
export const ViewEmailTemplatesDashboardPermission = "View Email Templates Dashboard";
export const EditCustomEmailTemplatePermission = "Edit Custom Email Template";
export const DeleteCustomEmailTemplatePermission = "Delete Custom Email Template";
/* -----------------End of Meeting Page Permissions--------------------- */

/* -----------------Contracts Terms------------------------ */
export const CreateContractsTermsPermission = "Create Contract Terms";
export const ViewContractsTermsDashboardPermission = "View Contract Terms Dashboard";
export const EditContractsTermsPermission = "Edit Contract Terms";
export const DeleteContractsTermsPermission = "Delete Contract Terms";
/* -----------------End of Contracts Terms--------------------- */

/* -----------------Reports Page Permissions------------------------ */
export const GenerateContractsReportPermission = "Generate Contracts Report";
export const GenerateUsersFeedbackReportPermission = "Generate Users Feedback Report";
/* -----------------End of Reports Page Permissions--------------------- */

/* -----------------Contract Page Permissions------------------------ */
export const DownloadCSVReportPermission = "Download CSV Report";
export const UploadContractsPermission = "Upload Contracts";
export const ReanalyzeContractsPermission = "Reanalyze Contracts";
export const SetContractPrecedenceOrderPermission = "Set Contract Precedence Order";
export const AttachContractsPermission = "Attach Contracts";
export const RemoveLinkedContractsPermission = "Remove Linked Contracts";
export const ViewContractsDashboardPermission = "View Contracts Dashboard";
export const DeleteContractsPermission = "Delete Contracts";
export const ProvideFeedbackPermission = "Provide Feedback";
export const DownloadContractPermission = "Download Contract";
/* -----------------End of Contract Page Permissions--------------------- */

/* -----------------User Page Permissions------------------------ */
export const ViewUsersDashboardPermission = "View Users Dashboard";
export const EditUsersPermission = "Edit Users";
export const ActivateInactivateUnlockUsersPermission = "Activate/Inactivate/Unlock Users";
export const AddUserPermission = "Add User";
/* -----------------End of User Page Permissions--------------------- */

/* -----------------Roles & Permission Page Permissions------------------------ */
export const ViewRolesDashboardPermission = "View Roles & Permissions Dashboard";
export const EditRolePermission = "Edit Role & Permissions";
export const DeleteRolePermission = "Delete Role & Permissions";
export const CreateRolesPermission = "Create Roles & Permissions";
/* -----------------Roles & Permission Page Permissions--------------------- */

/* -----------------User Profile Information Page Permissions------------------------ */
export const ViewMyProfilePermission = "View My Profile Dashboard";
export const EditMyProfilePermission = "Edit My Profile"; 
export const ChangePasswordPermission = "Change Password"; 
/* -----------------User Profile Information Page Permissions--------------------- */

/* -----------------Screening Terms Page Permissions------------------------ */
export const ViewScreeningTermsDashboardPermission = "View Screening Terms Dashboard";
export const AddScreeningTermsPermission = "Add Screening Terms";
export const EditScreeningTermsPermission = "Edit Screening Terms";
export const DeleteScreeningTermsPermission = "Delete Screening Terms";
/* -----------------Screen Terms Page Permissions--------------------- */

/* -----------------AI Messages Page Permissions------------------------ */
export const CreateAIMessagePermission = "Create Messages";
export const EditAIMessagePermission = "Edit Messages";
export const DeleteAIMessagePermission = "Delete Messages";
/* -----------------AI Messages Page Permissions--------------------- */

/* -----------------Pre-Processing Page Permissions------------------------ */
export const MovePreProcessPermission = "Move Pre-Processing Contracts";
export const DeletePreProcessPermission  = "Delete Pre-Processing Contracts";
export const RePreProcessPermission = "Reprocess Contracts";
/* -----------------Pre-Processing Page Permissions------------------------ */

/* -----------------Sales & Marketing Data Sync Page Permissions------------------------ */
export const SyncDataDashboard = "Sync Data Dashboard";
/* -----------------Sales & Marketing Data Sync Page Permissions--------------------- */
/* -----------------Access Self Data Permissions------------------------ */
export const AccessSelfData = "Access Self Data";
/* -----------------Access Self Data Permissions--------------------- */




