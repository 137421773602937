import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios, { AxiosResponse } from 'axios';
import { editScreeningTerms } from '../../networking/apiEndpoints';
import { ScreeningTermModelState } from '../../types/components/screeningTermModel';

const initialState: ScreeningTermModelState = {
    screeningTermModel:{
    status:false,
    message:"",
    result: []
 } ,
  loading: false,
  error:null,
  responseStatus: 200
};
// Define an async thunk to make the API call
export const editScreeningTermApi = createAsyncThunk<
AxiosResponse,
string,
  {
  rejectValue:AxiosResponse
}
>(
    'editScreeningTerm/get',
    async (id, thunkApi) => {
        try {
            const response = await axios.get(editScreeningTerms + '/' + id,{
                headers:{"Content-Type": "application/json",Authorization:`Bearer ${localStorage.getItem('token')}`},validateStatus: function(status){return status < 500;}
            });
            if(response.status === 401)
                {
                  return thunkApi.rejectWithValue(response);
                }         
              return response; 
        } catch (error) {
            throw error;
        }
    }
);
const editScreeningTermSlice = createSlice({
    name: 'editScreeningTerm',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(editScreeningTermApi.fulfilled, (state, action) => {
            state.screeningTermModel = action.payload.data;   
            state.loading = false;        
        });        
        builder.addCase(editScreeningTermApi.pending, (state, action) => {            
            state.loading = true;        
        })
        .addCase(editScreeningTermApi.rejected, (state, action) => {
            if(action.payload !== undefined)
              {
                state.responseStatus = action.payload.status;
              }
          });             
    },
});

export default editScreeningTermSlice.reducer;