import { createSlice,  createAsyncThunk } from "@reduxjs/toolkit";
import { UpdateUserDiscliamerLogsType } from "../../../types/components/rass/updateUserDiscliamerLogsType";
import axios from "axios";
import { updateUserDisclaimerLogs } from "../../../networking/apiEndpoints";

const initialState: UpdateUserDiscliamerLogsType = {
  isDeleted : false ,
  isLoading :false ,
  message : ""  
};
export const updateUserDiscliamerLogs  = createAsyncThunk(
    "updateUserDiscliamerLogs/update",
    async (userId : string) => {
        try {                    
          const UserDisclaimerAcceptanceModal = {userId : userId }        
          var token = localStorage.getItem('token');  
          const response = await axios.post(`${updateUserDisclaimerLogs}`, UserDisclaimerAcceptanceModal, {                            
          headers:{"Content-Type": "application/json", Authorization:`Bearer ${token}`}
        });    
                  
        return response.data;
      } catch (err) {
        console.log(err);
      }
    }
  );
  
  export const updateUserDisclaimerLogsSlice = createSlice({
    name: "updateUserDiscliamerLogs",
    initialState,
    reducers: {},
    extraReducers: (builder) => {  
      builder.addCase(updateUserDiscliamerLogs.fulfilled, (state, action ) => {
        
        if(action.payload?.status === 200)
        { 
          state.isDeleted  = true;
          state.isLoading = false;        
        }
        else{
            state.isDeleted = false;
            state.isLoading = false;  
        }
      });
      builder.addCase(updateUserDiscliamerLogs.pending, (state, action) => {
        state.isLoading = true;
    }) 
      },
  });
  
  export default updateUserDisclaimerLogsSlice.reducer;