import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios, { AxiosResponse } from "axios";
import { getAssignedUsersRoleEndPoint } from "../../networking/apiEndpoints";
import { IResponseModelState } from "../../types/components/responseModel";



const initialState: IResponseModelState = {
    responseModel:{
        status:false,
        message:"",
        result: null
     },
    loading: false,
    error: null,
    showAlert: false,
    responseStatus: 200
}


export const getAssignedUsersRole = createAsyncThunk<
AxiosResponse | any,
any,
{
    rejectValue: AxiosResponse;
}>(
    "getAssignedUsersRole/post",
    async (searchModel: { tenantId: string, roleId: string, isAssigned: boolean, name: string }, thunkApi) => 
        {
            const response = await axios.post(`${getAssignedUsersRoleEndPoint}` , searchModel, {
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${localStorage.getItem('token')}`,
                },
                validateStatus: function (status) {
                  return status < 500;
                },
              });
            if (response.status === 401) {
                return thunkApi.rejectWithValue(response);
            }
            return response.data;
        }
);

export const getAssignedUsersRoleSlice = createSlice({
    name: "getAssignedUsersRole",
    initialState,
    reducers: {
        hideAlert(state) {
            state.showAlert = false;
        },
        showAlert(state) {
            state.showAlert = true;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getAssignedUsersRole.pending, (state, action) => {
                state.loading = true;
                state.error = '';
                state.showAlert = false;
            })
            .addCase(getAssignedUsersRole.fulfilled, (state, action) => {
                state.responseModel = action.payload;
                if(action.payload !== undefined)
                    {
                      state.responseStatus = action.payload.status;
                    }
                state.loading = false;
                state.showAlert = true;
            })
            .addCase(getAssignedUsersRole.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message ?? '';
                state.showAlert = false;
                if(action.payload !== undefined)
                    {
                      state.responseStatus = action.payload.status;
                    }
            });
    },
});
export const { showAlert, hideAlert } = getAssignedUsersRoleSlice.actions;
export default getAssignedUsersRoleSlice.reducer;