import { createSlice,  createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { DeleteScreeningTerm, DeleteSliceScreeningTerm } from "../../types/components/screeningTermModel";
import { deleteScreeningTerms } from "../../networking/apiEndpoints";


const initialState: DeleteSliceScreeningTerm = {
  isDeleted : false,
  isLoading :false,   
};

export const deleteScreeningTermApi  = createAsyncThunk(
    "deletescreeningterm",
    async (deleteScreeningTermModel: DeleteScreeningTerm) => {
      try{
        var token = localStorage.getItem('token');
        const response = await axios.delete(deleteScreeningTerms, {
            data :  deleteScreeningTermModel,
            headers:{"Content-Type": "application/json",Authorization:`Bearer ${token}`}
        });
        return response.data;
      }
      catch (err) {
        console.log(err);
      }
    }
);
export const deleteScreeningTermSlice = createSlice({
  name: "deleteScreeningTerm",
  initialState,
  reducers: {       
  },
  extraReducers: (builder) => {
      builder.addCase(deleteScreeningTermApi.pending, (state, action) => {
          state.isLoading = true;
      })
      .addCase(deleteScreeningTermApi.fulfilled, (state, action ) => {
        if(action.payload === 200)
        { 
          state.isDeleted  = true;
          state.isLoading = false;        
        }
        else
        {
          state.isDeleted = false;
          state.isLoading = false;  
        }
      });
    }  
});
  
export default deleteScreeningTermSlice.reducer;