import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios, { AxiosResponse } from 'axios';
import { getpreviousnextcontracttermbyid } from '../../networking/apiEndpoints';
import { ContractTermIdByOrderNoContractType, ContractTermIdsModelState, NextPreviousContractTermModel } from '../../types/components/contractTermModel';

const conterTermIds: NextPreviousContractTermModel = {
    currentTermId:"",
    previousTermId:"",
    nextTermId:""
}
const initialState: ContractTermIdsModelState = {
    contractTermIdsModel:{
    status:false,
    message:"",
    result: conterTermIds
 },
    loading: false,
    error:null,
    responseStatus: 200
};
// Define an async thunk to make the API call
export const getNextPreviousContractTermIdsApi = createAsyncThunk<
AxiosResponse,
ContractTermIdByOrderNoContractType,
  {
  rejectValue:AxiosResponse
}
>(
    'contract/getNextPreviousContractTermIds',
    async (contractTermIdByOrderNoContractType :ContractTermIdByOrderNoContractType, thunkApi) => {
        try {
            var tenantId = localStorage.getItem('tenantId');
            const response = await axios.get(getpreviousnextcontracttermbyid + '/' + contractTermIdByOrderNoContractType.orderNo + '/' + contractTermIdByOrderNoContractType.contractType + '/' + tenantId,{
                headers:{"Content-Type": "application/json",Authorization:`Bearer ${localStorage.getItem('token')}`},validateStatus: function(status){return status < 500;}
            });
            if(response.status === 401)
                {
                  return thunkApi.rejectWithValue(response);
                }         
              return response; 
        } catch (error) {
            throw error;
        }
    }
);
const getNextPreviousContractTermIdsSlice = createSlice({
    name: 'contract',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getNextPreviousContractTermIdsApi.fulfilled, (state, action) => {
            state.contractTermIdsModel = action.payload.data;   
            state.loading = false;        
        });        
        builder.addCase(getNextPreviousContractTermIdsApi.pending, (state, action) => {            
            state.loading = true;        
        })
        .addCase(getNextPreviousContractTermIdsApi.rejected, (state, action) => {
            if(action.payload !== undefined)
              {
                state.responseStatus = action.payload.status;
              }
          });             
    },
});

export default getNextPreviousContractTermIdsSlice.reducer;