import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { getScreeningContractsStatus } from "../../networking/apiEndpoints";
import { PreProcessContractsStatusModel } from  "../../types/pages/getPreProcessModel";

export const fetchScreeningContractsStatusList = createAsyncThunk(
    "screeingContractsStatus/fetch",
    async (_, thunkApi) => { 
            var token = localStorage.getItem('token');
            const response = await axios.get(getScreeningContractsStatus, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                  },
                  validateStatus: function (status) {
                    return status < 500;
                  },
              });
            if(response.status === 401)
            {
              return thunkApi.rejectWithValue(response);
            }   
            return response.data;
    }
);

interface ContractsStatusState {
    result?: PreProcessContractsStatusModel[] | null;
    status: boolean;
    message: string;
    loading:boolean;
}

const initialState: ContractsStatusState = {
    result: [],
    loading: false,
    message: "",
    status:false
};

export const screeningContractsStatusListSlice = createSlice({
    name: "screeningContractsStatuslist",
    initialState,
    reducers: {
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchScreeningContractsStatusList.pending, (state) => {
                state.loading = true;
            })
            .addCase(fetchScreeningContractsStatusList.fulfilled, (state, action) => {
                state.result = action.payload.result;
                state.status = action.payload.status;
                state.loading = false;
            })
            .addCase(fetchScreeningContractsStatusList.rejected, (state, action) => {
                state.loading = false;    
                state.message = action.error.message ?? '';  
            });
    },
});
export default screeningContractsStatusListSlice.reducer;