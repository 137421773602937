import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import axios, { AxiosResponse } from "axios";
import { getScreeningContracts } from "../../networking/apiEndpoints";
import { PreProcessApiResponse, PreProcessContractsFilterModel } from "../../types/pages/getPreProcessModel";


const initialState: PreProcessApiResponse = {
  result: [],
  status:false,
  message:"",
  loading:false,
};

export const getScreeningContractsApi = createAsyncThunk<
PreProcessApiResponse,
PreProcessContractsFilterModel,
{
  rejectValue: PreProcessApiResponse;
}
>(
"getScreeningContracts/get",
async (filterModel, thunkApi) => {
    const response = await axios.post<PreProcessApiResponse>(`${getScreeningContracts}`, filterModel, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
      validateStatus: function (status) {
        return status < 500;
      },
    });

    if (response.status === 401) {
      return thunkApi.rejectWithValue(response.data);
    }
    return response.data;
}
);

export const getScreeningContractsSlice = createSlice({
  name: "getScreeningContracts",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
        .addCase(getScreeningContractsApi.pending, (state) => {
          state.loading = true;
        })
      .addCase(getScreeningContractsApi.fulfilled, (state, action: PayloadAction<PreProcessApiResponse>) => {
        state.result = action.payload.result;
        state.status = action.payload.status;
        state.loading = false;
      })
      .addCase(getScreeningContractsApi.rejected, (state, action) => {
          if(action.payload !== undefined)
            {
              state.status = action.payload.status;
              state.status = false;
              state.loading = false;

            }
        });  
  },
});

export default getScreeningContractsSlice.reducer;