import { useEffect, Suspense } from "react";
import "./assets/style.scss";
import LeftNav from "./layout/LeftNav";
import { Routes, Route, BrowserRouter, useRoutes } from "react-router-dom";
import Grid from "@mui/material/Grid";
import TopNav from "./layout/TopNav";
import Login from "./login";
import ForgotPassword from "./login/ForgotPassword";
import VerificationPage from "./login/VerificationPage";
import NotificationStatus from "./pages/cst/NotificationStatus";
import PasswordReset from "./login/PasswordReset";
import PorcoSetting from "./pages/cst/PortcoSetting/index";
import cstRoutes from "./routes/cstRoutes";
import rrasRoutes from "./routes/rrasRoutes";
import userRoutes from "./routes/userRoutes";
import FlashMessages from "./components/SharedComponents/FlashMessages";
import ErrorBoundaryWrapper from "./components/SharedComponents/CommonErrors/ErrorOverlayWrapper";
import InactivityHandler from "./login/loginInactivity";
import { usePortcoSetting } from "./redux/UserSlice/setPortcoHook";
import { useAuthSetting } from "./redux/UserSlice/setAuthHook";
import { useLoggedInUserInformation } from "./redux/UserSlice/setUserInfoHook";
import { systemAdminRoleName } from "./utilities/Constants";
import { selectedTenant } from "./redux/features/getAllTenantSlice";
import { useAppDispatch } from "./redux/store";
import { usePermissionNavList } from "./layout/LeftNav/permissionNavHook";
import { useModuleSwitcher } from "./redux/features/moduleSwitcher/moduleSwicherHook";
import { getIsCST, getIsRRAS } from "./utilities";

function AllRoutes() {
  const routes = useRoutes([...cstRoutes, ...rrasRoutes, ...userRoutes]);

  return (
    <ErrorBoundaryWrapper>
      <Suspense>{routes}</Suspense>
    </ErrorBoundaryWrapper>
  );
}
const App = () => {
  const {portco} =  usePortcoSetting();
  const {setAuth,auth} =  useAuthSetting();
  if (window !== null && window.top !== null && window !== window.top) {
    if (window.self !== window.top) {
      try {
        window.location.href = "https://www.google.com";
      } catch (ex) {
        window.location.href = "https://www.google.com";
      }
    }
  }
  useEffect(() => {
    const isAuthenticated = sessionStorage.getItem("isAuthenticated");
    if (isAuthenticated === "true") {
      setAuth(1);
    }
  }, []);

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={auth === 0 && <Login />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/notificationStatus" element={<NotificationStatus />} />
        <Route path="/verification-code" element={<VerificationPage />} />
        <Route
          path="/password-reset"
          element={<PasswordReset></PasswordReset>}
        />
      </Routes>
      {auth === 1 && (
        <><InactivityHandler />
        <Grid container spacing={0}>
          {portco !== "idle" ? 
            <>
              <FlashMessages />
              <Grid className="leftnav-main">
                <LeftNav />
              </Grid>
              <Grid className="rightmain-container">
                <TopNav />
                <AllRoutes />
              </Grid>
            </> : <RolesBasedPortcoSetting/>
          }
        </Grid>
        </>
      )}
    </BrowserRouter>
  );
};

const RolesBasedPortcoSetting = () => {
  const {Role, TenantId, IsCST, IsRRAS,} = useLoggedInUserInformation();
  const dispatch = useAppDispatch();
  const {setPortco} =  usePortcoSetting();
  const {setActiveTab} = useModuleSwitcher();
  const { LeftNavListREG, LeftNavListCST, navigateToVisibleItem } = usePermissionNavList();

  useEffect(() => {
    let userPermissionsIsCST = getIsCST();
    let userPermissionsIsRRAS = getIsRRAS();
    if (Role !== systemAdminRoleName) {
      const isRRAS = IsRRAS === "True"; 
      const isCST = IsCST === "True";
      if(userPermissionsIsRRAS && !userPermissionsIsCST){
        navigateToVisibleItem(LeftNavListREG);
        setActiveTab(1);
      }
      else if(!userPermissionsIsRRAS && userPermissionsIsCST)
      {
        navigateToVisibleItem(LeftNavListCST);
        setActiveTab(0);
      }
     else if (isRRAS && !isCST) {
        navigateToVisibleItem(LeftNavListREG);
        setActiveTab(1);
      } else {
        navigateToVisibleItem(LeftNavListCST);
        setActiveTab(0);
      }
    }
  }, []);

  const userRolesBasedModuleLanding = () => {
    setPortco("allowed");
    dispatch(selectedTenant(TenantId));
    localStorage.setItem("tenantId", TenantId);
  } 
  if (Role === systemAdminRoleName) {
    return <PorcoSetting />;
  } else {
    userRolesBasedModuleLanding();
    return null;
  }
};

export default App;
