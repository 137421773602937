import { createSlice,  createAsyncThunk } from "@reduxjs/toolkit";
import { ContractDetailsState  } from "../../types/components/contractDetailsTypes";
import axios from "axios";
import { getContractDetails, getPreProcessingContractDetails } from "../../networking/apiEndpoints";
import { PreProcessingContractDetailsState } from "../../types/components/preProcessingContractDetails.Types";

const initialState: PreProcessingContractDetailsState = {
    preProcessingContractDetails : {
        screeningContractId: '',
        title: '',
        updatedBy: '',
        updatedOn: '',
        fileName: '',
        path: '',
        screeningFieldDetails: [],
        screeningTermTags: [],
    },
    isloading : true,
};

export const fetchPreProcessingContractDetails = createAsyncThunk(
    "preProcessingContractDetails/fetch",
    async (preProcessingContractId : string) => {
        try{                
            var token = localStorage.getItem('token');
            const response = await axios.get(getPreProcessingContractDetails + "/" + preProcessingContractId,{
                headers:{"Content-Type": "application/json",Authorization:`Bearer ${token}`}
              });
            return response.data.result;
        } catch(err){
            console.log(err);
        }
    }
);

export const preProcessingContractDetailsSlice = createSlice({
    name: "preProcessingContractdetails",
    initialState,
    reducers: {       
    },
    extraReducers: (builder) => {
        builder.addCase(fetchPreProcessingContractDetails.fulfilled, (state, action) => {
            state.preProcessingContractDetails = action.payload;
            state.preProcessingContractDetails.screeningTermTags = action.payload.screeningTermTags.join(", ");
            state.isloading = false;                         
        });     
        builder.addCase(fetchPreProcessingContractDetails.pending, (state, action) => {
            state.isloading = true;                        
        });          
    },
});

export default preProcessingContractDetailsSlice.reducer;
