import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios, { AxiosResponse } from "axios";
import { saveScreeningTerms } from "../../networking/apiEndpoints";
import { IResponseModelState, ResponseModelState } from "../../types/components/responseModel";
import { SavePermissionsInterface } from "../../types/pages/roleType";
import { ScreeningTermModel } from "../../types/components/screeningTermModel";

const initialState: IResponseModelState = {
    responseModel:{
       status:false,
       message:"",
       result: null
    } ,
    loading: false,error:"",showAlert:false,
    responseStatus: 200
};
export const saveScreeningTermApi = createAsyncThunk<
AxiosResponse,ScreeningTermModel,
{
rejectValue:AxiosResponse
}
>(
    "saveScreeningTerm/post",
    async (screeningTermModel, thunkApi) => {
        var token = localStorage.getItem('token');     
            const response = await axios.post(saveScreeningTerms,screeningTermModel, {
                headers:{"Content-Type": "application/json",Authorization:`Bearer ${token}`},validateStatus: function(status){return status < 500;}
            })
            if(response.status === 401)
                {
                  return thunkApi.rejectWithValue(response);
                }  
            return response;    
    }
);

export const saveScreeningTermSlice = createSlice({
    name: "saveScreeningTerm",
    initialState,
    reducers: {       
    },
    extraReducers: (builder) => {
        builder.addCase(saveScreeningTermApi.fulfilled, (state, action) => {
            state.responseModel = action.payload.data;
            state.loading = false;                        
        }) 
        .addCase(saveScreeningTermApi.rejected, (state, action) => {           
            if(action.payload !== undefined)
              {
                state.responseStatus = action.payload.status;
              }
              state.loading = false;   
          })
         .addCase(saveScreeningTermApi.pending, (state, action) => {      
            state.loading = true;                        
        });   
    },
});

export default saveScreeningTermSlice.reducer;