import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { refreshToken } from "../../networking/apiEndpoints";
import { ResponseModelState } from "../../types/components/responseModel";
import {LoginApiResponseType} from "../../types/pages/LoginApiResponseType";

const initialState: ResponseModelState = {
    responseModel:{
       status:false,
       message:"",
       result: null
    } ,
    responseStatus: 200
};

export const refreshTokenApi = createAsyncThunk(
    "refreshToken/post",
    async (tokenModel: { accessToken: string; refreshToken: string }, { rejectWithValue }) => {
      try {
        const response = await axios.post(refreshToken, tokenModel, {
          headers: { "Content-Type": "application/json" },
        });
        return response.data;
      } catch (err: any) {
        console.error(err);
        return rejectWithValue(err.response.data);
      }
    }
  );

export const refreshTokenSlice = createSlice({
    name: "refreshToken",
    initialState,
    reducers: {       
    },
    extraReducers: (builder) => {
        builder.addCase(refreshTokenApi.fulfilled, (state, action) => {
            state.responseModel = action.payload;                        
        });        
    },
});

export default refreshTokenSlice.reducer;
